import { useState, useEffect, useContext } from 'react';
import { usePreferenceDetails } from '@sentara/sentara-api-hooks-core';
import ChangePhoneNumber from '../profile/ChangePhoneNumber';
import EmailChange from '../profile/EmailChange';
import HomeAddressDetails from '../profile/HomeAddressDetails';
import { GlobalContext } from 'context';
import { useTranslation } from 'react-i18next';
import {Address, userAddressTypeHome, Phone, getDataByAddressType, ApiWrapper} from 'common';
import { Headings, Loader } from '@sentaraui/optimahealth_web';


const MemberProfile = () => {
  //Initialize translation hook
  const { t } = useTranslation();
  const {preferenceDetails} = usePreferenceDetails();
  const [userAddress, setUserAddress] = useState<Address[]>([]);
  const [userPhone, setUserPhone] = useState<Phone[]>([]);
  const { getFeature, loginMemberId, proxyMemberId } = useContext(GlobalContext);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState<any>({});
  const { data: profileResponse, apiError : profileError, callApi } = ApiWrapper(preferenceDetails);

  useEffect(() => {
    if(getFeature?.AccountSettings !==undefined) {
      let data = {
        memberId: btoa(loginMemberId)
      }
      setLoader(true);
      callApi(data,proxyMemberId,getFeature?.AccountSettings);
    }
  }, [loginMemberId, getFeature?.AccountSettings]);

  useEffect(() => {
    if (profileError) {
      setLoader(false);
    } else if(profileResponse) {
      setState(profileResponse);
      setLoader(false);
    }
  }, [profileResponse, profileError]);

  
  const childrenstate = (
    <option value={state?.address && state?.address[0]?.state}>
      {state?.address && state?.address[0]?.state}
    </option>
  );

  useEffect(() => {
    (async () => {
      getDataByAddressType(state && state?.address, userAddressTypeHome, setUserAddress);
      getDataByAddressType(state && state?.phones, userAddressTypeHome, setUserPhone);
    })();
  }, [state && state?.dependents]);


  return (
    <>
      {loader && <Loader />}
      <div className="enroll_in_benefits black-color">
        <div className="heading-one-semibold mb-4">
          <Headings
            level={1}
            text={t('memberProfile.label.title')}
            data-testid="enrollBenefitsHeader"
          />
        </div>
        <div className="border border-clr border_radius_18 p-4 mb-4">
          <HomeAddressDetails
            canChangeContactInfo={state?.canChangeContactInfo}
            childrenstate={childrenstate}
            city={userAddress[0]?.city ?? ''}
            dependents={state?.dependents}
            hideUpdate={false}
            street={userAddress[0]?.street ?? ''}
            zipCode={userAddress[0]?.zipCode ?? ''}
          />
          {state?.phones && (
            <ChangePhoneNumber
              phoneNumber={userPhone[0]?.phoneNumber ?? ''}
              canChangeContactInfo={state?.canChangeContactInfo ? false : true}
            />
          )}

          <EmailChange emailId={state?.email} />
        </div>
      </div>
    </>
  );
};

export default MemberProfile;
