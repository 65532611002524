import { ProfileRegex } from 'common';
/* first letter interface */
interface FirstLetter {
  firstName: string;
  lastName: string;
}

const ProfileFirstLetter = ({ firstName = '', lastName = '' }: FirstLetter) => {
  /* Detemine user first letter */
  const getUserFirstLetter = () => {
    const name = `${firstName} ${lastName}`;
    const matches = name.match(ProfileRegex);
    return matches?.join('')?.substring(0, 2).toUpperCase() || '';
  };
  
  return (
    <>
      <div
        data-testid="firstLetter"
        className="d-flex rounded-circle mx-auto justify-content-center align-items-center profile_image profile_letter"
      >
        {getUserFirstLetter()}
      </div>
    </>
  );
}

export default ProfileFirstLetter;
