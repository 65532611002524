import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useSecurityQuestions,
  useProfileRegister,
  useGuestRegister,
} from '@sentara/sentara-api-hooks-core';
import { convertAPIRequestFormat, dateformat, termAndCondition, FormTitles } from 'common';
import UpdateSecurityQuestions from '../profile/UpdateSecurityQuestions';
import {
  Checkbox,
  ErrorMessage,
  Button,
  GlobalError,
  Headings,
  Loader
} from '@sentaraui/optimahealth_web';
import { useForm, FormProvider } from "react-hook-form";

interface FormData {
  [key: string]: string | undefined;
}

interface SecurityQuestionProps {
  formData: FormData[];
  setFormData: (data: FormData[]) => void;
  page: number;
  setPage: (response: any) => void;
  guest: boolean;
  plantypes?: string;
}

interface PersonalDataProps {
  firstName?: string;
  lastName?: string;
  emailId?: string;
  dateOfBirth?: string;
  ssn?: string;
  planId?: string;
  userId?: string;
  password?: string;
  questions?: any[];
}

const Ids = [
  { id: 'Q1', testId: 'One' },
  { id: 'Q2', testId: 'Two' },
  { id: 'Q3', testId: 'Three' },
];



const SecurityQuestion = ({
  formData,
  setFormData,
  page,
  setPage,
  guest,
  plantypes,
}: SecurityQuestionProps) => {
 
  const { t } = useTranslation();
  const { SecurityQuestion } = useSecurityQuestions();
  const { ProfileRegister } = useProfileRegister();
  const { GuestRegister } = useGuestRegister();
  const [item, setItems] = useState<any[]>([]);
  const [res, setRes] = useState<any[]>([]);
  const [errorActivation1, setErrorActivation1] = useState<boolean>(false);
  const [errorActivation2, setErrorActivation2] = useState<boolean>(false);
  const [errorActivation3, setErrorActivation3] = useState<boolean>(false);
  const [errorMemberExists, setErrorMemberExists] = useState<boolean>(false);
  const [errorActivation4, setErrorActivation4] = useState<boolean>(false);
  const [error, setError] = useState<any[]>([]);
  const [errorFlag, setErrorFlag] = useState<any[]>([]);
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [checkBoxError, setCheckBoxError] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number>();
   const methods = useForm({
    
   });
   const { handleSubmit,  formState: { isValid } } = methods;

  /** useEffect method used to call the Security question API to display the security question drop down*/
  useEffect(() => {
    (async () => {
      const response: any = await SecurityQuestion();
      setItems(response);
    })();
  }, [SecurityQuestion]);
  /** useEffect method used to check the front end validation*/
 

  /** Terms and condition component  */

  const termsAndConditionText = (
    <>
      <span className="redhat_medium">{t('activation.messages.accept')}</span>{' '}
      <a
        href={termAndCondition}
        target="_blank"
        rel="noreferrer"
        className="text-decoration-underline redhat_regular"
        data-testid="termsandconditionLink"
      >
        {t('termsandcondition.label.termsTitle')}
      </a>
    </>
  );

  /** Terms and condition checkbox front validation  */

  const checkboxValid = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckBox(true);
      setCheckBoxError(false);
    } else {
      setCheckBox(false);
    }
  };

  

  /** Handle change method used to show the error border in the security question field*/

  const onHandleChange = (index: number, value: string, key: string) => {
    const tempRes = [...res];
    tempRes[index] = { ...tempRes[index], [key]: value };
    const tempErr = [...error];
    tempErr[index] = {
      ...tempErr[index],
      [key]: value === '' ? 'error-border' : '',
    };
    const tempErrFlag = [...errorFlag];
    tempErrFlag[index] = {
      ...tempErrFlag[index],
      [key]: value === '',
    };
    setError(tempErr);
    setErrorFlag(tempErrFlag);
    setRes(tempRes);
  };

 

  /**API Call to validate the Member register*/
  const onSubmit = async () => {
    if (checkBox === false) {
      setCheckBoxError(true);
    }
    if (checkBox === true) {
      setLoader(true);
      let personalData: PersonalDataProps = {};
      let data = {};
      personalData = {
        firstName: formData[1]?.firstName,
        lastName: formData[1]?.lastName,
        emailId: formData[2]?.emailID,
        dateOfBirth: convertAPIRequestFormat((formData[1]?.dob || ''), dateformat.dateFormatISO),
        ssn: formData[1].ssn ? formData[1]?.ssn : formData[1]?.guest,
        planId: '',
        userId: formData[3]?.name,
        password: formData[3]?.password,
        questions: res,
      };
      if (!guest) {
        if (formData[1].planType === t('activation.label.getmedicaId')) {
          personalData.planId = t('activation.label.getmedicaId') || '';
        } else if (
          formData[1].planType === t('activation.label.getmedicareId')
        ) {
          personalData.planId = t('activation.label.getmedicareId') || '';
        } else {
          personalData.planId = t('activation.label.individual') || '';
        }
        const medicaId = formData[1].medicaId || '';
        const medicareId = formData[1].medicareId || '';
        const memberIdData = {
          medicaId: medicaId,
          medicareId: medicareId,
        };
        data = Object.assign(personalData, memberIdData);
      }
      let result: any;
      if (!guest) {
        result = await ProfileRegister(data);
      } else {
        result = await GuestRegister(personalData);
      }
      type ErrorCodesType = {
        [key: number]: (value: boolean) => void;
      };
      // Error codes and their corresponding error messages
      const errorCodes:ErrorCodesType = {
          439: setErrorActivation1,
          400: setErrorActivation1,
          454: setErrorActivation2,
          456: setErrorActivation3,
          504: setErrorActivation3,
        };
        // If the error code is present in the errorCodes object, then set the corresponding error message
        if (result.errorCode && result.errorCode in errorCodes) {
          errorCodes[result.errorCode](true);
        } else {
          setErrorCode(result.errorCode);
          setErrorActivation4(true);
        }

        if (result?.data?.activationStatus === false) {
          setErrorMemberExists(true);
        }
      else if (result?.data?.activationStatus === true) {
        setErrorActivation1(false);
        setErrorActivation2(false);
        setErrorActivation3(false);
        setErrorActivation4(false);
        setErrorMemberExists(false);
        setPage((currPage: number) => currPage + 1);
      }
      setLoader(false)
    }
  };
  //Display the errror message based on the error code
  const globalError = [
          { condition: errorActivation1, message: 'activation.errors.activationError1' },
          { condition: errorActivation2, message: 'activation.errors.activationError2' },
          { condition: errorActivation3, message: 'activation.errors.activationError3' },
          { condition: errorMemberExists, message: 'activation.errors.memberAlreadyRegistered' },
          { condition: errorActivation4, message: 'activation.errors.activationError4', errorCode: errorCode },
  ];
  return (
    <>
    <FormProvider {...methods}>
      <form data-testid="submit_form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {loader && <Loader />}
        <div className='row'>
        <div aria-live="polite" className="col-lg-6 col-md-6 col-sm-12 col-12">
          {globalError.map((error, index) => 
            error.condition && (
              <GlobalError variant="mb-3" dataTestId="userNameInputError">
                    {error.errorCode ? `${error.errorCode} ${t(error.message)}` : t(error.message)}
              </GlobalError> 

            )
          )}
          {FormTitles[page] !== '' && (
            <>
              <span className="visually-hidden me-1">
                {t('accessibility.message.registrationCurrentPage')} {[page + 1]}
              </span>
              <div className="pb-4 mb-4">
                <Headings
                  dataTestId="registrationHeading"
                  level={1}
                  text={t(FormTitles[page])}
                />
              </div>
            </>
          )}
        </div>
        </div>

        <UpdateSecurityQuestions
          securityQuestion1={item}
          hasStyle={true}
          placeholder={false}
          {...{
            Ids,
            res,
            setRes,
            onHandleChange,
          }}
        />
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 col-12">
              <div className="d-flex align-items-center  pos-rel">
                <Checkbox
                  id="termAndConditioncheck"
                  className={
                    checkBoxError
                      ? 'form-check-input mt-0 error-input-bdr'
                      : 'form-check-input mt-0'
                  }
                  children={termsAndConditionText}
                  type="checkbox"
                  dataTestId="termsandconditionCheckbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    checkboxValid(e)
                  }
                  aria-describedby="termsofuse_error"
                />
              </div>
              <div
                  id="termsofuse_error"
                  aria-live="polite"
                  aria-atomic="true"

                >
                  {checkBoxError && (
                    <div className="input-error-bottom">
                      <span className="visually-hidden">{t('errorWord')}</span>
                      <ErrorMessage
                        children={t(
                          'activation.errors.acceptTermsAndCondition'
                        )}
                      ></ErrorMessage>
                    </div>
                  )}
                </div>
            </div>
          </div>
          <hr />
          <div className="row pt-4 pb-2">
            <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
              <Button
                dataTestId={'submitButton'}
                children={t('activation.label.continue')}
                variant={
                  isValid && checkBox
                    ? 'primary_btn btn_block_mob'
                    : 'btn_block_mob disabled_btn'
                }
                type="submit"
              />
            </div>
            <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
              <Button
                dataTestId={'cancelButton'}
                children={t('activation.label.cancel')}
                variant={'secondary_btn'}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  setFormData({
                    ...formData,
                    ...{
                      [page]: {},
                    },
                  });
                  setPage((currPage: number) => currPage - 1);
                }}
              />
            </div>
          </div>
        </div>
      </form>
      </FormProvider>
    </>
  );
};

export default SecurityQuestion;
