
import { Headings, Button } from '@sentaraui/optimahealth_web';

type TreatmentCostCardProps = {
  featureEnabled: boolean;
  heading: string;
  description: string;
  dataTestId: string;
  buttonLabel: string;
  getAPIUrl: () => void;
};

const TreatmentEstimateCards: React.FC<TreatmentCostCardProps> = ({ featureEnabled, heading, description, dataTestId,buttonLabel,getAPIUrl }) => {
  return featureEnabled ? (
    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
      <div className="border border-1 p-4 border_radius_18 h-100 d-flex">
        <div className="d-flex flex-column justify-content-between">
          <div className="mb-3">
            <div className="pb-3">
              <Headings
                level={2}
                text={heading}
                dataTestId={`${dataTestId}`}
              />
            </div>
            <p data-testid={`${dataTestId}Text`}>
              {description}
            </p>
          </div>
          <Button
            variant="primary_btn w-100"
            dataTestId={`${dataTestId}ViewButton`}
            onClick={getAPIUrl}
            children={buttonLabel}
          />
        </div>
      </div>
    </div>
  ) : null;
}

export default TreatmentEstimateCards;