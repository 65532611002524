import { useState, useEffect, useContext } from 'react';
import { t } from 'i18next';
import { useSaveEmail } from '@sentara/sentara-api-hooks-core';
import { useForm } from 'react-hook-form';
import { EmailRegex } from 'common';
import { ErrorMessage, InputField, Label, Button } from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';

interface EmailChangeProps {
  emailId: string;
}

const EmailChange = ({ emailId }: EmailChangeProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [email, setEmail] = useState<string>(emailId);
  const { getFeature, proxyMemberId } = useContext(GlobalContext);
  
  const { saveEmail } = useSaveEmail(); /* save email API call*/
  const [errorMessage, setErrorMessage] =
    useState<string>(''); /* set error message */
  /* react hook form props call from useForm*/
  const {
    handleSubmit,
    register,
    setError,
    clearErrors,
  } = useForm({});
  /* enable email field */
  const enableEmailField = () => {
    if (isDisabled) {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    setEmail(emailId);
  }, [emailId]);


  /* save email api call on submit */
  const onSubmit = async (e: React.FormEvent) => {
    e?.preventDefault();

    if (!isValidEmail(email) || email?.length < 0) {
      setError('emailId', {
        type: 'manual',
        message: t('settings.errors.email') ?? '',
      });
      return;
    }

    clearErrors('emailId'); // Clear previous errors
    await saveEmail(proxyMemberId,getFeature?.AccountSettings, email);
    setIsDisabled(true);
  };

  /* check email valid */
  function isValidEmail(email: string) {
    return EmailRegex.test(email);
  }
  /* email change error message */
  function onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!isValidEmail(e.target.value)) {
      setErrorMessage(t('settings.errors.email') ?? '');
    } else {
      setErrorMessage('');
    }
    setEmail(e.target.value);
  }

  const isErrorPresent = errorMessage !== "";
  const isDisabledClass = isDisabled ? "secondary_btn" : "primary_btn";
  const errorClass = isErrorPresent ? "btn-medium profile-update-btn" : "btn-medium profile-update-btn error_btn_height";
  const variant = `${isDisabledClass} ${errorClass}`;

  return (
    <form onSubmit={handleSubmit(() => onSubmit)}>
      <div
        className={`d-flex flex-lg-row flex-md-row flex-column gap-3 align-items-start align-items-lg-center align-items-md-center mb-3`}
      >
        <div className="flex-1 w-100">
          <div className="pos-rel max_height">
            <Label
              dataTestId={'emailUpdateLabel'}
              htmlFor="emailInput"
              children={t('settings.label.emailAddress')}
              className="input-label"
            />
            <InputField
              dataTestId="emailInput"
              className={
                errorMessage ? 'error-border form-input' : 'form-input'
              }
              type="email"
              value={email}
              placeholder={t('settings.label.emailAddress')}
              disabled={isDisabled}
              {...register('emailId', {
                required: true,
                validate: (value) => value !== '',
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onEmailChange(e)
              }
            />
            {errorMessage && (
              <div className="mt-1">
                <ErrorMessage
                  className={'m-0'}
                  dataTestId={'emailUpdate'}
                  children={errorMessage}
                />
              </div>
            )}
          </div>
        </div>
        <Button
          onClick={(e: React.MouseEvent<HTMLElement>) =>
            isDisabled ? enableEmailField() : onSubmit(e)
          }
          data-testid={'submitButton'}
          type="submit"
          variant={variant}
          data-aria-label={t('accessibility.message.changeEmailAddress')}
        > 
          {t('settings.label.update')}
        </Button>
      </div>
    </form>
  );
};

export default EmailChange;
