import { t } from 'i18next';
import { ContactInfo } from '@sentaraui/optimahealth_web';

interface PhoneResponse {
  getPhone?: {
    benefitPhone1800?: string;
    benefitPhoneLocal?: string;
  };
}

const AlternativeAddressDetails = (getPhone: PhoneResponse) => {
  const response = getPhone;
  return (
    <div data-testid="alternativeAddressDetails">
      {response?.getPhone  && (
        <div>
          {t('settings.message.addAlternate')}{' '}
          <ContactInfo
            phoneData={{
              phone1800: response?.getPhone?.benefitPhone1800,
              phoneLocal: response?.getPhone?.benefitPhoneLocal,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AlternativeAddressDetails;
