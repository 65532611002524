export const errorCodeToStateUpdate = (
  setNoContent: Function,
  setUnauthError: Function,
  setPassCriteria: Function,
  setTokenExpiry: Function,
  setServerError: Function
) => ({
  '204': setNoContent,
  '401': setUnauthError,
  '439': setPassCriteria,
  '441': setPassCriteria,
  '442': setPassCriteria,
  '443': setPassCriteria,
  '445': setPassCriteria,
  '448': setPassCriteria,
  '462': setTokenExpiry,
  '435': setServerError,
  '400': setServerError,
  '404': setServerError,
  '500': setServerError,
});

// Used to map the password strength value to the actual strength
export const PASSWORD_CRITERIA : Record<number,string> = {
	2: 'weak',
	3: 'medium',
	4: 'strong'
}