import {
  AppProvider,
  UserLoginProvider,
  MemberRegisterProvider,
  MemberUserNameProvider,
  ForgetPasswordProvider,
  ForgotUserNameProvider,
  PreferenceDetailsProvider,
  MySSOUrlProvider,
  MyPlanProvider,
  MemberIDCardProvider,
  CardsPdfProvider,
  MemberIDRequestProvider,
  MyDeleteProvider,
  MemberServiceProvider,
  DocumentLinksProvider,
  BenefitsProvider,
  MyConsolidatedProvider,
  AuthorizationDepedentProvider,
  MyDepedentAccessProvider,
  AuthorizationDetailsProvider,
  CoveredEligibleProvider,
  PlanDocumentProvider,
  MyPlanSOBProvider,
  MyPlanEOCProvider,
  GetDependentAccessProvider,
  MedicalClaimEOBProvider,
  MedicalClaimDetailsProvider,
  MedicalClaimTaxSummaryProvider,
  MemberIDFaxProvider,
  MedicalClaimListProvider,
  ContactusProvider,
  MyFeatureAttributeProvider,
  MyHomeTilesProvider,
  CSRPhiProvider,
  CareGiverProvider,
  UpdatePlanProvider,
  ChattestProvider,
  FeaturePermissionProvider,
} from '@sentara/sentara-api-hooks-core';
import { DeviceTypeProvider, caregiverEndPoint } from 'common';
import { ThemeProvider } from '@sentaraui/optimahealth_web';
import { useContext } from 'react';
import { GlobalContext } from '../context';

export const AppWrapper = ({
  header,
  authToken,
  children,
}: {
  header: Record<string, string | undefined>;
  authToken: string;
  children: React.ReactNode;
}) => {

    const { getFeature } = useContext(GlobalContext);
    
  return (
    <AppProvider
      config={{
        httpConfig: {
          baseURL: process.env.REACT_APP_BASE_URL,
          authToken: authToken || '',
          commonHeaders: header,
          caregiverBaseURL: caregiverEndPoint,
          CIAMEnabled: getFeature?.isModernizedAPI || false,
          CIAMBaseURL: process.env.REACT_APP_CIAM_BASE_URL,
        },
      }}
    >
      <UserLoginProvider>
        <ChattestProvider>
          <ForgetPasswordProvider>
            <MyHomeTilesProvider>
              <AuthorizationDetailsProvider>
                <ForgotUserNameProvider>
                  <AuthorizationDepedentProvider>
                    <MemberRegisterProvider>
                      <MyDepedentAccessProvider>
                        <DocumentLinksProvider>
                          <MemberUserNameProvider>
                            <MyPlanProvider>
                              <MyConsolidatedProvider>
                                <BenefitsProvider>
                                  <MySSOUrlProvider>
                                    <MemberIDCardProvider>
                                      <CardsPdfProvider>
                                        <MemberIDRequestProvider>
                                          <PreferenceDetailsProvider>
                                            <MyDeleteProvider>
                                              <MemberServiceProvider>
                                                <CoveredEligibleProvider>
                                                  <PlanDocumentProvider>
                                                    <MyPlanSOBProvider>
                                                      <MyPlanEOCProvider>
                                                        <MedicalClaimTaxSummaryProvider>
                                                          <MemberIDFaxProvider>
                                                            <GetDependentAccessProvider>
                                                              <MedicalClaimEOBProvider>
                                                                <MedicalClaimDetailsProvider>
                                                                  <MedicalClaimListProvider>
                                                                    <ContactusProvider>
                                                                      <MyFeatureAttributeProvider>
                                                                      <DeviceTypeProvider>
                                                                        <CSRPhiProvider>
                                                                          <CareGiverProvider>
                                                                            <UpdatePlanProvider>
                                                                              <FeaturePermissionProvider>
                                                                                <ThemeProvider>
                                                                                  {
                                                                                    children
                                                                                  }
                                                                                </ThemeProvider>
                                                                              </FeaturePermissionProvider>
                                                                            </UpdatePlanProvider>
                                                                          </CareGiverProvider>
                                                                        </CSRPhiProvider>
                                                                        </DeviceTypeProvider>
                                                                      </MyFeatureAttributeProvider>
                                                                    </ContactusProvider>
                                                                  </MedicalClaimListProvider>
                                                                </MedicalClaimDetailsProvider>
                                                              </MedicalClaimEOBProvider>
                                                            </GetDependentAccessProvider>
                                                          </MemberIDFaxProvider>
                                                        </MedicalClaimTaxSummaryProvider>
                                                      </MyPlanEOCProvider>
                                                    </MyPlanSOBProvider>
                                                  </PlanDocumentProvider>
                                                </CoveredEligibleProvider>
                                              </MemberServiceProvider>
                                            </MyDeleteProvider>
                                          </PreferenceDetailsProvider>
                                        </MemberIDRequestProvider>
                                      </CardsPdfProvider>
                                    </MemberIDCardProvider>
                                  </MySSOUrlProvider>
                                </BenefitsProvider>
                              </MyConsolidatedProvider>
                            </MyPlanProvider>
                          </MemberUserNameProvider>
                        </DocumentLinksProvider>
                      </MyDepedentAccessProvider>
                    </MemberRegisterProvider>
                  </AuthorizationDepedentProvider>
                </ForgotUserNameProvider>
              </AuthorizationDetailsProvider>
            </MyHomeTilesProvider>
          </ForgetPasswordProvider>
        </ChattestProvider>
      </UserLoginProvider>
    </AppProvider>
  );
};
