import { deleteEncryptedValue } from 'common';

export const TilesIds = {
	findDoctor: 'FindaDoctor',
	viewClaims: 'MedicalClaimsandExplanations',
	ViewIDCard: 'MemberIDCard',
	BenefitsSnapshot: 'Benefits',
	MyProfile: 'MyProfile',
	UrgentCareSearch: 'UrgentCare',
	ContactUs: 'ContactUs',
	MyPlan: 'YourPlan',
	FormsAndDocuments: 'FormsandDocuments',
	VirtualVisit: 'MDLIVEVirtualVisit',
	PayMonthlyPremium: 'PayMonthlyPremium',
	HealthReimbursementAccount: 'ManageHealthReimbursementAccount',
	HealthSavingsAccount: 'ManageHealthSavingsAccount',
	Authorizations: 'Authorizations',
};

export const dateformat = {
	dateFormatMDY: 'MM/DD/YYYY',
	isodateFormatMDY: 'MM/dd/yyyy',
	dateFormatISO: 'yyyy-MM-dd',
	dateFormatHypen: 'MM-dd-yyyy',
	dayMonth: 'MM/DD/',
};

export const SSOKeys = {
	findDoctor: 'HelathSparqOneSSOUrl',
	viewClaims: '',
	ViewIDCard: '',
	BenefitsSnapshot: '',
	MyProfile: '',
	UrgentCareSearch: 'HelathSparqOneSSOUrl',
	ContactUs: '',
	MyPlan: '',
	FormsAndDocuments: '',
	VirtualVisit: 'MDLiveSSOUrl',
	PayMonthlyPremium: 'WellsFargo',
	HealthReimbursementAccount: 'ChoiceStrategiesSSOUrl',
	HealthSavingsAccount: 'HealthEquitySSOUrl',
	Authorizations: '',
};

export const MyPlanTabs = {
	overviewTabs: 'overview_tabs',
	memberDetailsTabs: 'member_details_tabs',
	designatedTabs: 'designated_representative_tabs',
	profileDob: 'profileDob',
	overViewID: '1',
	memberDetailID: '2',
	designatedID: '3',
};

export const profileTabs = {
	profileDetailsTab: 'profile-details',
	setMyPreferenceTab: 'acc-settings',
	profileDetailsID: '1',
	setMyPreferenceID: '2'
};

export const UserDetails = {
	guest: 'guest',
	firstName: 'firstName',
	lastName: 'lastName',
	emailId: 'emailId',
	usedId: 'usedId',
	serviceNumber: 'ServiceNumber',
	preferenceDepedent: 'preferenceDepedent',
	memberId: 'memberId',
	inActiveSignOut: 'inActiveSignOut',
	optimaPersonCookie: 'OptimaPersonCookie',
	optimaRoleCookie: 'OptimaRoleCookie',
	authToken: 'authToken',
	id: 'id',
	mId: 'mId',
	proxyId:'proxyId',
	key:'key',
	proxyMemberId: 'proxyMemberId',
	memberIdData: 'memberIdData',
	claimsId:'claimsId',
	claimsType:'claimsType',
	memberSuffix: 'memberSuffix',
};

export const Proxy = {
	MemberId: 'MemberId',
	DesignatedRepresentative: "DesignatedRepresentative",
	DesignatedDropdownLink: "DesignatedDropdownLink",
	memberProxy: "memberProxy",
	updateFeature: "updateFeature"

};

export const RequestStatus = {
	status401: 'Request failed with status code 401',
	status440: 'Request failed with status code 440',
};

export const getMemberPlan = {
	dependents: 'dependents',
	planName: 'planName',
};

export const getProxyDetails = {
	confirmation: 'Confirmation',
	proxy: 'Proxy',
	mobile: 'mobile',
	expired: '7',
	confirmed: '6',
	response: 'string',
	member: 'member',
	key: 'KEY',
	registered: '0',
	maxAttempts: 2,
	maxThreeAttempts: 3,
	expiredMessage:'expired',
	delete: 'delete',
	canceled: 'canceled',
	update: 'update',
	updated: 'updated',
	deny: 'deny',
	guardianUpdate: 'csr-update',
	OPEN: '1',
	PENDING_FOR_APPROVAL: '2',
	REJECTED: '3',
	APPROVED: '4',
	PENDING_FOR_APPROVAL_REVOCATION: '5',
	REVOCATION_REJECTED: '8',
	REVOCATION_APPROVED: '9',
	email: 'email',
	cancel: 'canceled',
	deleted:'deleted',
	success: 'success',
	proxyAuthorizationData:'proxyAuthorizationData'
};

export const getMaxLength = {
	employeeAccessCode: 20,
	ssn: 4,
	guestSSN: 9,
	memberId: 20,
	medicare:50,
	userName: 20,
	email: 100,
	name: 30,
	password: 64,
	phoneNumber: 12,
	maxPhoneNumber:10,
	securityQuestion:65
};

export const errorhandler = (error) => {
	const unAuthorizedError = [401, 440];
	const unAuthError = unAuthorizedError.includes(error);
	if (unAuthError) {
		if (typeof window !== 'undefined') {
			localStorage.clear();
			deleteEncryptedValue();
			window.location.href = '/sign-in';
		}
	}
};
export const ExpiryOption_Othert = 'Othert';
export const ExpiryOption_Custom = 'custom';
export const ExpiryOption_Until_Disenrollment = "until-disenrollment";
export const PhoneType_Mobile = "Mobile";


export const REQUEST = 'request';

export const ProfileUpdate = 'profileUpdate';


export const backToDashboardLink = "backToDashboardLink";
export const MemberProxyData = "MemberProxyData";

export const userAddressTypeHome = "HOME"


export const sessionIdleTime = 900;
export const MINIMIZE_TIMEOUT = 15000;
export const API_STATUS_SUCCESS = '200';
export const claimsDefaultDate = 18;
export const month = 'month';
export const internalServerErrorCode = '500';
export const preferencesDetails = {
 English: "English",
 Spanish: "Spanish",
 ExplanationOfBenefits: "Explanation of Benefits",
 SummaryOfBenefits: "Summary of Benefits and Coverage",
 OtherDocuments: "Other Documents",
};

export const featureToggleConstants = {
	accountDeletion: 'AccountDeletion',
	accountSettings: 'AccountSettings',
	allowParentViewClaims: 'AllowParentViewClaims',
	allowspouseviewclaims: 'Allowspouseviewclaims',
	appreviewPrompt: 'AppreviewPrompt',
	authorizations: 'Authorizations',
	behavioralClaims: 'BehavioralClaims',
	behavioralHealthSearch: 'BehavioralHealthSearch',
	benefits: 'Benefits',
	benefitsCoverage: 'BenefitsCoverage',
	careATC: 'CareATC',
	changePCP: 'ChangePCP',
	contactUs: 'ContactUs',
	contactusSalesForce: 'ContactusSalesForce',
	coveredEligibleMembers: 'CoveredEligibleMembers',
	OrderMemberIDCard:'OrderMemberIDCard',
	dentalDominionSso: 'DentalDominionSso',
	designatedRepresentativeTab: 'DesignatedRepresentativeTab',
	doctorAndMedications: 'DoctorAndMedications',
	doctorSearch: 'DoctorSearch',
	documentDeliveryPreferences: 'DocumentDeliveryPreferences',
	emergencyTravelAssistance: 'EmergencyTravelAssistance',
	enrollInBenefits: 'EnrollInBenefits',
	eob: 'Eob',
	estimateTreatmentCosts: 'EstimateTreatmentCosts',
	findADoctor: 'FindADoctor',
	findCare: 'FindCare',
	findDoctorsOrProviders: 'FindDoctorsOrProviders',
	forgeRockSecurityQuestionandAnswer: 'ForgeRockSecurityQuestionandAnswer',
	formsandDocuments: 'FormsandDocuments',
	formulary: 'Formulary',
	frequentlyAskedQuestions: 'FrequentlyAskedQuestions',
	getHealthy: 'GetHealthy',
	hSQEstimateTreatmentCosts: 'HSQEstimateTreatmentCosts',
	healthSparqOneCostCalculator: 'HealthSparqOneCostCalculator',
	homeTiles: 'HomeTiles',
	hospitalFacilityAndOtherServices: 'HospitalFacilityAndOtherServices',
	iBMEstimateTreatmentCosts: 'IBMEstimateTreatmentCosts',
	jivaCarePlan: 'JivaCarePlan',
	liveChat: 'LiveChat',
	loginScreenDisable: 'LoginScreenDisable',
	mDLiveVirtualVisit: 'MDLiveVirtualVisit',
	manageHSA: 'ManageHSA',
	manageHealthReimbursementAccount: 'ManageHealthReimbursementAccount',
	manageorRenewPolicy: 'ManageorRenewPolicy',
	medicalClaims: 'MedicalClaims',
	memberRegistration: 'MemberRegistration',
	memberServicesNumbers: 'MemberServicesNumbers',
	memberidCard: 'MemberidCard',
	myProfile: 'MyProfile',
	memberAccount: 'MemberAccount',
	memberProfile: 'MemberProfile',
	niceIncontact: 'NiceIncontact',
	omniChannelLiveChat: 'OmniChannelLiveChat',
	orderMemberIDCard: 'OrderMemberIDCard',
	payMonthlyPremium: 'PayMonthlyPremium',
	paymentsBillingAndClaims: 'PaymentsBillingAndClaims',
	personalHealthAssessment: 'PersonalHealthAssessment',
	personalHealthRecord: 'PersonalHealthRecord',
	pharmacyBenefitsManager: 'PharmacyBenefitsManager',
	pharmacyClaims: 'PharmacyClaims',
	pharmacyResources: 'PharmacyResources',
	pharmacySearch: 'PharmacySearch',
	planDocuments: 'PlanDocuments',
	planList: 'PlanList',
	prescriptionDrugs: 'PrescriptionDrugs',
	prodSSLCert: 'ProdSSLCert',
	profilePCP: 'ProfilePCP',
	sMGProvider: 'SMGProvider',
	sSLCert_v: 'SSLCert-v',
	testiOS: 'TestiOS',
	updateEmailPrompt: 'UpdateEmailPrompt',
	urgentCareSearch: 'UrgentCareSearch',
	viewAnnualDeductiblesandMaximums: 'ViewAnnualDeductiblesandMaximums',
	viewCarePlan: 'ViewCarePlan',
	viewClaims: 'ViewClaims',
	viewIDCard: 'ViewIDCard',
	viewTaxYearSummary: 'ViewTaxYearSummary',
	visionCare: 'VisionCare',
	visionTest: 'VisionTest',
	wellnessTools: 'WellnessTools',
	yourPlan: 'YourPlan',
	faxIDCard: 'faxIDCard',
	newIDCardFlow: 'newIDCardFlow',
	printIDCard: 'printIDCard',
	shareIDCard: 'shareIDCard',
	// For not logged in user
	optimaHealth: 'Optima Health',
	members: 'Members',
	providers: 'Providers',
	employers: 'Employers',
	brokers: 'Brokers',
};

export const FormTitles = [
    'Get Started',
    'Personal Information',
    'Email Verification',
    'Username & Password',
    'Security Questions',
    '',
];


export const proxyViewFeatureList = [
	featureToggleConstants.accountDeletion,
	featureToggleConstants.accountSettings,
	featureToggleConstants.benefitsCoverage,
	featureToggleConstants.formsandDocuments,
	featureToggleConstants.myProfile,
	featureToggleConstants.changePCP,
	featureToggleConstants.contactUs,
	featureToggleConstants.documentDeliveryPreferences,
	featureToggleConstants.homeTiles,
]

export const proxyViewInfoFeatureList = {
	accountInformation: [
		featureToggleConstants.benefitsCoverage,
		featureToggleConstants.yourPlan,
		featureToggleConstants.benefits,
		featureToggleConstants.planDocuments,
		featureToggleConstants.coveredEligibleMembers,
		featureToggleConstants.memberidCard,
		featureToggleConstants.OrderMemberIDCard,
		featureToggleConstants.faxIDCard,
		featureToggleConstants.formsandDocuments,
		featureToggleConstants.authorizations,
		featureToggleConstants.memberAccount,
		featureToggleConstants.memberProfile
	],
	medicalClaims: [
		featureToggleConstants.benefitsCoverage,
		featureToggleConstants.formsandDocuments,
		featureToggleConstants.authorizations,
		featureToggleConstants.paymentsBillingAndClaims,
		featureToggleConstants.medicalClaims,
		featureToggleConstants.viewTaxYearSummary,
		featureToggleConstants.estimateTreatmentCosts,
		featureToggleConstants.eob,
	],
	changePCP: [
		featureToggleConstants.doctorAndMedications,
		featureToggleConstants.changePCP,
	],
	common: [
		featureToggleConstants.contactUs,
		featureToggleConstants.contactusSalesForce,
		featureToggleConstants.myProfile,
		featureToggleConstants.accountDeletion,
		featureToggleConstants.accountSettings,
	]
}
export const PROXY_EXPIRED_URL = '/proxy-expired-form';
export const CSR_PROXY_UPDATE_URL = '/csr-proxy-update'
export const CSR_PROXY_REVOKE_URL = '/csr-proxy-revoke'
export const PROXY_APPROVE_URL = '/proxy-approve'
export const PROXY_REGISTER_URL = '/proxy-register'
export const PROXY_AUTHENTICATION = '/proxy-authentication'
export const AUTH_SUCCESS_URL = '/iam-authentication-success'
