import React, { useState,useMemo, useContext } from 'react';
import { t } from 'i18next';
import {
  Label,
  ErrorMessage,
  InputField,
  Button, 
  SelectDropdown
} from '@sentaraui/optimahealth_web';
import { useFormContext } from 'react-hook-form';
import { EyeIconClose, EyeIconOpen,} from 'common'
import { GlobalContext } from 'context';


interface UpdateSecurityQuestionsProps {
  securityQuestion1: any;
  hasStyle?: boolean;
  Ids: { id: string; testId: string }[];
  res: any;
  setRes?: any;
  onHandleChange: (index: number, value: string, field: string) => void;
  placeholder: boolean;
}

const UpdateSecurityQuestions = ({
  securityQuestion1,
  hasStyle = false,
  Ids,
  res,
  onHandleChange,
  placeholder,
}: UpdateSecurityQuestionsProps) => {
  /* check error */
const { zipValue, addressValue, } = useContext(GlobalContext);
  const methods = useFormContext();
  const {setValue,watch, formState: { errors } } = methods;
 const [passwordOpen, setPasswordOpen] = useState<boolean[]>([false, false, false]);

 const isAddressChanged = useMemo(() => {
    return (
      zipValue !== '' ||
      addressValue !== ''
    );
    
  }, [zipValue, addressValue]);

  return (
    
    <div className="row">
      <div
        className={
          !hasStyle
            ? 'col-lg-12 col-md-12 col-sm-12 col-12'
            : 'col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12'
        }
        data-testid="updateSecurityQuestionsDiv"
      >
        <div>
          {[...Array(3)].map((_, index) => {
            return (
              <div className="row" key={`security-${Ids[index].testId}}`}>
                <div
                  className={
                    !hasStyle
                      ? 'col-lg-6 col-md-6 col-sm-12 col-12'
                      : 'col-lg-12 col-md-12 col-sm-12 col-12'
                  }
                >
                  <div className="mb-3 pos-rel">
                    <Label
                      className="input-label"
                      dataTestId={`securityQuestionsTitleText${Ids[index].testId}`}
                      htmlFor=""
                      children={t(`activation.label.securityQt${[index + 1]}`)}
                    />
                    <Label className="input-label" />
                    
                    <SelectDropdown
                      className={`form-select ${errors[`question${index}`] ? 'error-border' : ''}`}
                      id={`regPlanType${Ids[index].id}`}
                      dataTestId={`securityQuestionsSelect${Ids[index].testId}`}
                      {...methods.register(`question${[index]}`, { 
                        required: hasStyle,
                        onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                         setValue(`question${[index]}`, e.target.value);
                        onHandleChange(index, e.target.value, 'question')
                        }
                      })}
                    >
                      <option value='' selected  disabled={res[index]} >
                        {t('activation.label.selectSecurity')}
                      </option>
                      {securityQuestion1?.data
                      ?.map((item: any) => {
                        const isDisable =
                          res[index]?.question !== item.questionText;
                        return (
                          <option
                            key={`security-item${item.questionText}`}
                            className={
                              res
                                .reduce((arr: any, item: any) => {
                                  arr.push(item?.question);
                                  return arr;
                                }, [])
                                .includes(item.questionText) && isDisable
                                ? 'd-none'
                                : ''
                            }
                            
                            disabled={
                              res
                                .reduce((arr: any, item: any) => {
                                  arr.push(item?.question);
                                  return arr;
                                }, [])
                                .includes(item.questionText) && isDisable
                            }
                            value={item.questionText}
                            selected={!isDisable}
                            
                          >
                            {item.questionText}
                          </option>
                        );
                      })}
                    </SelectDropdown>
                     {errors[`question${index}`]?.type === 'required' && (
                      <div className="mt-1">
                        <ErrorMessage
                          dataTestId={`enterAnswerText${Ids[index].testId}ErrorMessage`}
                          children={t('activation.errors.securityQts')}
                        />
                      </div>  
                    )}
                  </div>
                </div>
                     
                <div
                  className={
                    !hasStyle ? 'col-lg-6 col-md-6 col-sm-12 col-12' : ''
                  }
                >
                  <div className="mb-3 pos-rel">
                    <Label
                      className="input-label"
                      dataTestId={`securityAnswerTitleText${Ids[index].testId}`}
                      htmlFor=""
                      children={t('Answer')}
                    />
                    <div className="d-block align-items-center pos-rel">
                      <div className="pos-rel flex-1">
                    <InputField
                       className={`form-input psw-input ${errors[`questionAnswer${index}`] ? 'error-border' : ''}`}
                      placeholder={
                        placeholder
                          ? '****************'
                          : t('activation.label.answerPlaceholder')
                      }
                      type={!passwordOpen[index] ? 'password' : 'text'}
                      dataTestId={`enterAnswerText${Ids[index].testId}`}
                      {...methods.register(`questionAnswer${[index]}`, { 
                        required: !isAddressChanged,
                        minLength: 3,  
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          setValue(`questionAnswer${[index]}`, e.target.value);
                          onHandleChange(index, e.target.value, 'questionAnswer')
                        }
                      })} 
                     
                      id={`enterAnswerText${Ids[index].testId}`}
                    />
                     {errors[`questionAnswer${index}`]?.type === 'required' && (
                      <div className="mt-1">
                        <ErrorMessage
                          dataTestId={`enterAnswerText${Ids[index].testId}ErrorMessage`}
                          children={t('activation.errors.secQtsAnswer')}
                        />
                      </div>  
                    )}
                   {errors[`questionAnswer${index}`]?.type === 'minLength' && (
                      <div className="mt-1">
                        <ErrorMessage
                          dataTestId={`enterAnswerText${Ids[index].testId}ErrorMessage`}
                          children={t('activation.errors.secQtsAnswerLenghtCheck')}
                        />
                      </div>  
                    )}
                   
                    {(watch(`questionAnswer${index}`)) && (
                    <Button
                          dataTestId="passwordInputEyeIcon"
                          className="eye_icon"
                          onClick={(e: { preventDefault: () => void }) => {
                            e.preventDefault();
                            const newOpenStates = [...passwordOpen];
                              newOpenStates[index] = !newOpenStates[index];
                              setPasswordOpen(newOpenStates);
                          }}
                        >
                          {/* Toggle eye icon based on password visibility */}
                          {!passwordOpen[index] ? (
                            <span
                              className="visually-hiiden"
                              aria-label="Eye icon button click to show the password"
                            >
                              <EyeIconClose />
                            </span>
                          ) : (
                            <span
                              className="visually-hiiden"
                              aria-label="Eye icon button click to hide the password"
                            >
                              <EyeIconOpen />
                            </span>
                          )}
                        </Button>
                    )}
                    </div>
                    </div>
                   
                  </div>                  
                      </div>  
                </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UpdateSecurityQuestions;
