import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceTypes, formatISODateToMDY, useDeviceType } from 'common';
import { useNavigate } from 'react-router-dom';
import { Accordion } from '@sentaraui/optimahealth_web';

// AuthorizationTable component
const AuthorizationTable: React.FC<any> = ({
  authorizationTable,
  setAuthorizationTable,
  memberId,
}) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');
  const navigate = useNavigate();

  // Function to handle sorting change
  const handleSortingChange = (accessor: string) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };
  // Function to handle sorting of the authorization table
  const handleSorting = (sortField: string, sortOrder: string) => {
    if (sortField) {
      const sorted: Array<any> = [...authorizationTable].sort(
        (a: any, b: any) => {
          if (a[sortField] === null) {
            return 1;
          }
          if (b[sortField] === null) {
            return -1;
          }
          if (a[sortField] === null && b[sortField] === null) {
            return 0;
          }
          return (
            String(a[sortField]).localeCompare(String(b[sortField]), 'en', {
              numeric: true,
            }) * (sortOrder === 'asc' ? 1 : -1)
          );
        }
      );
      setAuthorizationTable(sorted);
    }
  };

  // Function to navigate to authorization details page
  const navigateToDetails = (item: { authorizationNumber: any }) => {
    setAuthorizationTable([]);
    navigate('/home/authorizations/authorizationDetails', {
      state: {
        memberId: memberId,
        authId: item.authorizationNumber,
      },
    });
  };
  const deviceType = useDeviceType();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <>
      {!isMobile && (
        <div className="overflow-scroll">
          <table
            className="table table-responsive table-custom-style authorization-table black-color font-fourteen mb-4 print"
            id={'viewauthorizationDetails'}
          >
            <thead>
              <tr>
                <th data-testid="authNumberTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0 not-print"
                    onClick={() => handleSortingChange('authorizationNumber')}
                  >
                    {t('authorization.label.authorizationNumber')}
                  </button>
                  <span aria-hidden="true" className='print-theader'>{t('authorization.label.authorizationNumber')}</span>
                </th>
                <th data-testid="authEffectiveDateTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0 not-print"
                    onClick={() => handleSortingChange('effectiveDate')}
                  >
                    {t('authorization.label.effectiveDate')}
                  </button>
                  <span aria-hidden="true" className='print-theader'>{t('authorization.label.effectiveDate')}</span>
                </th>
                <th data-testid="authReferredToTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0 not-print"
                    onClick={() =>
                      handleSortingChange('referredToProviderName')
                    }
                  >
                    {t('authorization.label.referredTo')}
                  </button>
                  <span aria-hidden="true" className='print-theader'>{t('authorization.label.referredTo')}</span>
                </th>
                <th data-testid="authRefferedByTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0 not-print"
                    onClick={() => handleSortingChange('referredBy')}
                  >
                    {t('authorization.label.referredBy')}
                  </button>
                  <span aria-hidden="true" className='print-theader'>{t('authorization.label.referredBy')}</span>
                </th>
                <th data-testid="authStatusTab">
                  <button
                    className="sort-by white-color bg-transparent border-0 ps-0 m-0 not-print"
                    onClick={() => handleSortingChange('status')}
                  >
                    {t('authorization.label.status')}
                  </button>
                  <span aria-hidden="true" className='print-theader'>{t('authorization.label.status')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {authorizationTable?.length > 0 &&
                authorizationTable.map((item: any, i: any) => {
                  return [
                    <tr key={item.mId}>
                      <td
                        className="radio-center"
                        data-testid={`authorizationNumber${i}`}
                      >
                        <button
                          className="darkblue-color bg-transparent border-0 text-decoration-underline"
                          onClick={(e) => {
                            e.preventDefault();
                            navigateToDetails(item);
                          }}
                          data-testid={`authorizationNumber_${i}`}
                        >
                          {item.authorizationNumber}
                        </button>
                      </td>

                      <td
                        className="radio-center"
                        data-testid={`effectiveDate${i}`}
                      >
                        {formatISODateToMDY(item.effectiveDate)}
                      </td>
                      <td
                        className="radio-center"
                        data-testid={`referredToProviderName${i}`}
                      >
                        {item.referredToProviderName}
                      </td>
                      <td
                        className="radio-center"
                        data-testid={`referredBy${i}`}
                      >
                        {item.referredBy}
                      </td>
                      <td className="radio-center" data-testid={`status${i}`}>
                        {item.status}
                      </td>
                    </tr>,
                  ];
                })}
              {authorizationTable?.length === 0 && (
                <tr>
                  <td colSpan={5} className="text-center">
                    {t('noResults')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {isMobile &&
        (authorizationTable?.length > 0 ? (
          <div className="accordion-table mb-4">
            <Accordion
              initialActiveIndex={0}
              items={
                authorizationTable?.length > 0 &&
                authorizationTable?.map((item: any, i: any) => ({
                  title: (
                    <div>
                      <div data-testid={`authNumberTab${i}`}>
                        <span className="font-sixteen">
                          {t('authorization.label.authorizationNumber')}
                        </span>
                      </div>
                      <div data-testid={`authorizationNumber${i}`}>
                        <button
                          className="darkblue-color bg-transparent border-0 text-decoration-underline"
                          onClick={(e) => {
                            e.preventDefault();
                            navigateToDetails(item);
                          }}
                        >
                          {item.authorizationNumber}
                        </button>
                      </div>
                    </div>
                  ),
                  content: (
                    <div>
                      <div
                        data-testid={`authEffectiveDateTab${i}`}
                        className="redhat_bold"
                      >
                        {t('authorization.label.effectiveDate')}
                      </div>
                      <div data-testid={`effectiveDate${i}`} className="mb-3">
                        {formatISODateToMDY(item.effectiveDate)}
                      </div>
                      <div
                        data-testid={`authReferredToTab${i}`}
                        className="redhat_bold"
                      >
                        {t('authorization.label.referredTo')}
                      </div>
                      <div
                        data-testid={`referredToProviderName${i}`}
                        className="mb-3"
                      >
                        {item.referredToProviderName}
                      </div>
                      <div
                        data-testid={`authRefferedByTab${i}`}
                        className="redhat_bold"
                      >
                        {t('authorization.label.referredBy')}
                      </div>
                      <div data-testid={`referredBy${i}`} className="mb-3">
                        {item.referredBy}
                      </div>
                      <div
                        data-testid={`authStatusTab${i}`}
                        className="redhat_bold"
                      >
                        {t('authorization.label.status')}
                      </div>
                      <div data-testid={`status${i}`} className="mb-3">
                        {item.status}
                      </div>
                    </div>
                  ),
                }))
              }
            />
          </div>
        ) : (
          <div className="mb-4">
            <div className="text-center">{t('noResults')}</div>
          </div>
        ))}
    </>
  );
};

export default AuthorizationTable;
