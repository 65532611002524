import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { EmailRegex, ProfileUpdate, UserDetails, getDecryptedValue } from 'common';
import { useSaveEmail } from '@sentara/sentara-api-hooks-core';
import { Button, ErrorMessage } from '@sentaraui/optimahealth_web';
import { GlobalContext } from 'context';

interface EmailPromptProps {
  setIsOpen: (response: any) => void;
}

const EmailPrompt = ({ setIsOpen }: EmailPromptProps) => {
  const { t } = useTranslation();
  const { getFeature, proxyMemberId } = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const [emailErrMsg, setEmailErrMsg] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>('true');

  const [email, setEmail] = useState<string>('');
  const { saveEmail } = useSaveEmail();

  function popupClose() {
    localStorage.setItem(ProfileUpdate, "false");
    setIsOpen(false);
  }

  const saveData = async (data: FieldValues) => {
    const res = await saveEmail(proxyMemberId,getFeature?.AccountSettings,data?.emailAddress, data?.radio);
    if (!res.error) {
      setIsOpen(false);
    }
    popupClose();
  };

  useEffect(() => {
    (async () => {
      const PersonalInfo = await getDecryptedValue(UserDetails.guest);
      const userData = PersonalInfo ? JSON.parse(PersonalInfo):{};
      setEmail(userData?.user?.emailId);
    })();
  }, [email]);

  const handleDependentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
  };
  return (
    <form
      onSubmit={handleSubmit(saveData)}
      autoComplete="off"
      className="text-normal"
    >
      <h3 className="modal-heading mb-2">
        {t('appModel.label.emailAddressCorrect')}
      </h3>
      <p className="mb-4">{t('appModel.message.optimaHealth180DaysMsg')}</p>
      <label className="pb-2">
        {t('appModel.label.yourCurrentEmailAddress')}
      </label>
      <div className="pos-rel mb-2">
        <input
          id="regEmailAddress"
          defaultValue={email}
          className={`form-input ${errors.emailAddress ? 'error-border' : ''}`}
          placeholder={t('yourPlan.label.emaillblPlaceholder') || ''}
          data-testid="emailInput"
          {...register('emailAddress', {
            validate: (value) => value !== '',
            pattern: {
              value: EmailRegex,
              message: t('activation.errors.emailId'),
            },
          })}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            if (errors.emailAddress) {
              setEmailErrMsg(true);
            } else if (e.target.value !== null && e.target.value !== '') {
              setEmailErrMsg(false);
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (
              errors.emailAddress ||
              (e.target.value !== null && e.target.value !== '')
            ) {
              setEmailErrMsg(false);
            }
          }}
        />
        {emailErrMsg && (
          <ErrorMessage
            dataTestId="emailInputError"
            children={t('activation.errors.emailId')}
          />
        )}
      </div>
      <p className="font-fourteen mb-4">
        {t('appModel.message.validEmailAddressMsg')}
      </p>

      <div className="fw-600 mb-2">{t('appModel.label.makeChangesFor')}</div>
      <div className={'form-check d-flex align-items-center pos-rel mb-2'}>
        <input
          className={'form-check-input my-0'}
          type="radio"
          checked={selectedOption === 'true'}
          data-testid="flexRadioDefault1"
          id="flexRadioDefault1"
          value="true"
          {...register('radio')}
          onChange={handleDependentChange}
        />
        <label
          className="font-normal primary-color ms-2"
          htmlFor="flexRadioDefault1"
        >
          {t('appModel.label.myself')}
        </label>
      </div>
      <div className="form-check mb-2">
        <input
          className={'form-check-input my-0'}
          type="radio"
          data-testid="flexRadioDefault2"
          value="false"
          checked={selectedOption === 'false'}
          id="flexRadioDefault2"
          {...register('radio')}
          onChange={handleDependentChange}
        />
        <label
          className="font-normal primary-color ms-2"
          htmlFor="flexRadioDefault2"
        >
          {t('appModel.label.mySelfUnder18')}
        </label>
      </div>

      <p className="font-small mb-4">
        {t('appModel.message.emailUnderAge18Msg')}
      </p>
      <div className="d-flex flex-column flex-lg-row flex-md-row gap-3">
        <Button
          dataTestId={'continueButton'}
          children={t('activation.label.continue')}
          variant={'primary_btn btn_block_mob'}
          type="submit"
        />
        <Button
          dataTestId={'cancelButton'}
          children={t('activation.label.cancel')}
          variant={'secondary_btn btn_block_mob'}
          type="button"
          onClick={popupClose}
        />
      </div>
    </form>
  );
}

export default EmailPrompt;