import React, { useState,  } from 'react';
import { getMaxLength,  HelpIcon, ModalCustomStyle,  signIn, memberIdReg, forgotUsername, FormTitles } from 'common';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import MemberIdOverlay from './MemberIdOverlay';
import { Buffer } from 'buffer';
import { useRegisterMember } from '@sentara/sentara-api-hooks-core';
import { useNavigate, Link } from 'react-router-dom';
import MemberInfoModal  from './MemberInfoModal';

import {
  ErrorMessage,
  Label,
  CallSupport,
  Button,
  InputField,
  GlobalError,
  SelectDropdown,
  Headings,
  Loader
} from '@sentaraui/optimahealth_web';
import { useForm } from 'react-hook-form';
interface GetStartedProps {
  setPage: (response: any) => void;
  plantypes?: string;
  page: number;
  formData: any;
  setFormData: (response: any) => void;
  setPlantypes: (response: any) => void;
  item: any;
}

type FormInputs = {
  regMemId: string;
  planType: string;
}

/** GetStarted component starts here*/
function GetStarted({
  setPage,
  setPlantypes,
  setFormData,
  page,
  formData,
  item,
}: GetStartedProps) {
  const { t } = useTranslation(); /** translation function*/
  const { RegisterMember } = useRegisterMember(); /** API function  for register member*/
  const [errorMessage, setErrorMessage] = useState<boolean>(false); /** Setting default error message*/
  const [noContent, setNoContent] = useState<boolean>(false); /** default error meesage for 204 no content*/
  const [loader, setLoader] = useState<boolean>(false); /** Default loader value set to false*/
  const [isOpen, setIsOpen] = useState<boolean>(false); /** Default open value set to false*/
  const [memberErrorModal, setMemberErrorModal] = useState<boolean>(false);
  const [memberErrorMessage, setMemberErrorMessage] = useState<string>("");
  const { handleSubmit, register, watch,setValue,  getValues, reset,  formState: { errors }, setError, clearErrors} = useForm<FormInputs>({
    
  defaultValues: {
    regMemId: formData[page]?.regMemId || '',
    planType: formData[page]?.planType || '',
  }
  }); /* useForm props extract */

  /** navigation function*/
  const navigate = useNavigate();
  /** constant value for MedicareId*/
  const ACTIVATION_LABEL_MEDICAREID = t('activation.label.getmedicareId');
  const ACTIVATION_LABEL_MEDICAID = t('activation.label.getmedicaId');
  const ACTIVATION_LABEL_INDIVIUALEMPLOYER = t(
    'activation.label.individualEmployer'
  );


  /** API call for Mmeber ID valdation*/
  const onSubmit = async () => {   
    const { regMemId, planType } = getValues();
    if(planType !== '' && regMemId === '') {
      register('regMemId', { required:false});
    }
    if( regMemId !=="" && planType === '') {
      register('planType', { required:false});
      
    }

    if( regMemId ==="" && planType === '') {
      setError('planType', { type: "required"});
      setError('regMemId', { type: "required"});
    }
    let plan  = {
      'regMemId': regMemId,
      'planType': planType
    }
    if (regMemId) {
      const memberId = {
        memberId: Buffer.from(regMemId).toString('base64'),
      };
      const response = await RegisterMember(memberId);
      handleApiResponse(response);
    } 
    else if (planType) {
      if (planType === ACTIVATION_LABEL_INDIVIUALEMPLOYER) {
        setPlantypes(t('activation.label.individual'));
      } else if (planType === ACTIVATION_LABEL_MEDICAID) {
        setPlantypes(ACTIVATION_LABEL_MEDICAID);
      } else if (planType === ACTIVATION_LABEL_MEDICAREID) {
        setPlantypes(ACTIVATION_LABEL_MEDICAREID);
      }
      
      setPage((currPage: number) => currPage + 1);
    }
    setFormData({ ...formData, [page]: plan});
  };

  const handleApiResponse = (response: any) => {
    if(response?.errorCode=== 403 && response?.errorDetails?.detail) {
      setLoader(false);
      setMemberErrorModal(true);
      setMemberErrorMessage(response?.errorDetails?.detail);
      return;
    }
    else if (response?.data?.isActivated === true) {
      setLoader(false);
      setErrorMessage(true);
      return;
    } else if (response?.data === '' || response?.errorCode) {
      setNoContent(true);
      setLoader(false);
      return;
    } else if (response?.data?.individualMember === true) {
      setPlantypes(t('activation.label.individual'));
    } else if (response?.data?.medicareMember === true) {
      setPlantypes(ACTIVATION_LABEL_MEDICAREID);
    } else if (response?.data?.medicaidMember === true) {
      setPlantypes(ACTIVATION_LABEL_MEDICAID);
    }
    
    setPage(1);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const closeMemberErrorModal = () => {
    setMemberErrorModal(false);
    setMemberErrorMessage("")
  }

  /**Display the  Plantype drop down dynamically*/
  const childrenData = (
    <>
      <option selected disabled={true} value=''>
        {t('activation.label.selectPlanType')}
      </option>
      {item?.data?.map((value: {planName: string}) => (
        <option
          data-testid="select-option"
          value={value.planName}
        >
          {value.planName}
        </option>
      ))}
    </>
  );

  /**Display the errormessage when Memberid API throws error*/
  const renderErrorMessage = () => {    
    if (errorMessage) {
      return (
          <GlobalError data-testid="memberExistId"  variant="mb-3">
            {
                <p className='mb-0'> {t('activation.errors.getStartedMemberAlreadyRegistered1')}
                  <Link to={signIn}> {t('account.label.sign')} </Link>
                  {t('activation.errors.getStartedMemberAlreadyRegistered2')}
                  <Link to={forgotUsername}> {t('account.label.forgotUsername')} </Link>
                  {t('activation.errors.getStartedMemberAlreadyRegistered3')}
                </p>
            }
          </GlobalError> 
      );
    }
    if (noContent) {
      return (
        <GlobalError  variant="mb-3" dataTestId="forgotUsernameMemIdError">
                     {t('requestUserId.message.serviceError')}
        </GlobalError> 
      );
    }
    return null;
  };


  /**Display the error message for plantype down*/
  const renderPlanTypeErrorMessage = () => {
    if (errors.planType) {
      return <ErrorMessage children={t('activation.errors.planType')} />;
    }
    return null;
  };

  return (
    <>
      <form className="get_started" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {loader && <Loader />}
        
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
           <div aria-live="polite">
            {renderErrorMessage()}
            {FormTitles[page] !== '' && (
            <>
              <span className="visually-hidden me-1">
                {t('accessibility.message.registrationCurrentPage')} {[page + 1]}
              </span>
              <div className="pb-4 mb-4">
                <Headings
                  dataTestId="registrationHeading"
                  level={1}
                  text={t(FormTitles[page])}
                />
              </div>
            </>
          )}
           </div>
            <div className="mb-1 pos-rel">
              <Label
                className="pb-1"
                dataTestId="memberIdLabel"
                htmlFor="regMemId"
                children={t('activation.label.memberIdlabel')}
              />
              <div className="align-items-center">
                <div className="pos-rel flex-1 d-flex">
                  <InputField
                    id="regMemId"
                    data-testid="memberId"
                    maxLength={getMaxLength.memberId}
                    placeholder={t('forgetUser.label.enterMemberID')}
                    className={`form-input ${errors.regMemId ? 'error-border' : ''}`}
                    {...register('regMemId', {
                        onChange: (e:React.ChangeEvent<HTMLInputElement>) => {
                        setValue('regMemId', e.target.value.replace(memberIdReg, ''));
                        reset({ planType: '' }); 
                        e.target.value === '' ? setError('regMemId', { type: "required" }) : clearErrors('regMemId')
                    },                            
                    })}                    
                  />
                   <div className="ms-2">
                      <Button
                        data-testid="memIdHelpInfo"
                        onClick={openModal}
                        aria-label="Information icon click to view"
                        variant="border-0 bg-transparent"
                        type={'button'}
                      >
                        <HelpIcon />
                      </Button>
                </div>
                </div>
                {errors.regMemId && (
                  <div className="mt-1">
                      <ErrorMessage children={t('requestUserId.message.memberIdRequired')}  />
                  </div>
                )} 
               
              </div>
            </div>
            <div className="or-text pt-2 pb-2 mt-2 mb-2" data-testid="getStartedOrText">
              {t('activation.label.or')}
            </div>
            <div className="mb-3 pos-rel">
              <div>
                <Label
                  className="pb-1"
                  dataTestId="planTypeLabel"
                  htmlFor="regPlanType"
                  children={t('activation.label.plantype')}
                />
              </div>
              <SelectDropdown
                dataTestId="planTypeSelect"
                className={`form-select ${errors.planType ? 'error-border' : ''}`}
                id="planType"         
                 {...register("planType", { 
                  onChange: (e:React.ChangeEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    reset({ regMemId: '' }); 
                    setValue('planType', e.target.value);
                    e.target.value === '' ? setError('planType', { type: "required" }) : clearErrors('planType')
                  },                
                }
                 )}               
              >
                {childrenData}
              </SelectDropdown>             
              {watch('planType') === t('activation.label.idontknow') && (
                <div className="pt-3">
                  <div className="mb-3">
                    <CallSupport dataTestId="memServiceNum">
                      {t('activation.messages.callMember1')}
                    </CallSupport>
                  </div>
                  <div className="mb-3">
                    <CallSupport dataTestId="mainNum">
                      {t('activation.messages.callMember2')}
                    </CallSupport>
                  </div>
                  <div className="mb-3">
                    <CallSupport dataTestId="hearingImpairmentNum">
                      {t('activation.messages.callMember3')}
                    </CallSupport>
                  </div>
                </div>
              )}
              <div aria-live="polite" className="mt-1">{renderPlanTypeErrorMessage()}</div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row pt-4 pb-2">
          {watch('planType') === t('activation.label.idontknow') ? null : (
           <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4">
              <Button
                dataTestId={'continueButton'}
                children={t('activation.label.continue')}
                variant={
                  getValues('regMemId') !== '' || getValues('planType') !== ''
                    ? 'primary_btn btn_block_mob'
                    : 'btn_block_mob disabled_btn'
                }
                type="submit"
              />
            </div>
          )}
         
          <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4">
            <Button
            type="button"
              dataTestId={'cancelButton'}
              children={t('activation.label.cancel')}
              variant={'secondary_btn btn_block_mob'}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                navigate(signIn);
              }}
            />
          </div>
        </div>
      </form>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        data-testid="memberIdModal"
        contentLabel="Member Id Informal Overlay"
        style={ModalCustomStyle}
      >
        <div className="w-member-overlay">
          <div className="text-right">
            <Button
              type="button"
              data-testid="btn-close"
              className="btn-close"
              onClick={closeModal}
              aria-label="Close"
            >
              <span aria-hidden="true" className="visually-hidden">
                {t('appointments.buttonLableTwoClose')}
              </span>
            </Button>
          </div>
          <MemberIdOverlay />
        </div>
      </Modal>
      {memberErrorMessage && <MemberInfoModal closeMemberErrorModal={closeMemberErrorModal} memberErrorMessage={memberErrorMessage} memberErrorModal={memberErrorModal}/>}
    </>
  );
}

export default GetStarted;
