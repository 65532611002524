import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeIconClose, EyeIconOpen, PasswordRegex, getMaxLength } from 'common';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  Label,
  ErrorMessage,
  InputField,
  
} from '@sentaraui/optimahealth_web';

// Define the interface for the props passed to PasswordInputPage component
export interface ChangePasswordProps {
  // Handler for onKeyDown event
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  // Password length indicator
  setValue: (value: string) => void;
  setNoContent ? : (value: boolean) => void;
  setUnauthError ? : (value: boolean) => void;
  setPassCriteria ? : (value: boolean) => void;
  setServerError ? : (value: boolean) => void;
  showPasswordPlaceholder?: string;
}

// Define the PasswordInputPage component
const ChangePassword: React.FC<ChangePasswordProps> = ({
  onKeyDown,
  setValue,
  setNoContent,
  setUnauthError,
  setPassCriteria,
  setServerError,
  showPasswordPlaceholder,
}) => {
  const { t } = useTranslation();

  const methods = useFormContext();
  const {formState: { errors } } = methods;

  // Regular expression for password validation

  // State to manage password visibility
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);


  return (
    <>
      <div className="mb-3 pos-rel">
        {/* Label for the input */}
        <Label
          dataTestId={'passwordInputLabel'}
          htmlFor="password"
          className="form-label redhat_medium"
          children={t('settings.label.newPassword')}
        />
        {/* Display asterisk if password length is less than 2 */}
          <span className="text-danger px-1">*</span>
        {/* Container for input and icon */}
        <div className="d-block align-items-center singin-input pos-rel">
          <div className="pos-rel flex-1">
            {/* Input field */}
            <InputField
              className={
                errors?.password
                  ? 'error-border form-input psw-input'
                  : 'form-input psw-input'
              }
              autoComplete="password"
              placeholder={showPasswordPlaceholder ? showPasswordPlaceholder : '********'}
              dataTestId="passwordInput"
              maxLength={getMaxLength.password}
              type={!passwordOpen ? 'password' : 'text'}
              onKeyDown={onKeyDown}
              onFocus={() => {
                if(setNoContent)setNoContent(false);
                if(setUnauthError)setUnauthError(false);
                if(setPassCriteria)setPassCriteria(false);
                if(setServerError)setServerError(false);
              }}
              {...methods.register("password", { 
                required:true,
                pattern: {
                    value: PasswordRegex,
                    message: "Password criteria not match"
                },
                onChange:(e)=>{
                  setValue(e.target.value);
                }
              })} 
            />
            {/* Button to toggle password visibility */}
            <Button
              dataTestId="passwordInputEyeIcon"
              className="eye_icon"
              onClick={(e: { preventDefault: () => void }) => {
                e.preventDefault();
                setPasswordOpen(!passwordOpen);
              }}
            >
              {/* Toggle eye icon based on password visibility */}
              {!passwordOpen ? (
                <span
                  className="visually-hiiden"
                  aria-label="Eye icon button click to show the password"
                >
                  <EyeIconClose />
                </span>
              ) : (
                <span
                  className="visually-hiiden"
                  aria-label="Eye icon button click to hide the password"
                >
                  <EyeIconOpen />
                </span>
              )}
            </Button>
          </div>

          {/* Display error messages */}
          {errors?.password?.type === 'required' && (
            <div className="mt-1">
              <ErrorMessage
                dataTestId={'passwordRequireError'}
                children={window.location.pathname.includes('activation') ? t('activation.errors.passwordrequired') : t('settings.errors.newpassword')}
              />
            </div>  
          )}
          {errors?.password?.type === 'pattern' && (
            <div className="mt-1">
              <ErrorMessage
                dataTestId={'passwordRequireError'}
                children={t('activation.errors.passwordrequired')}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
