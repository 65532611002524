export const toSentancePascalCase = (text: string) => {
  return text?.split(' ')?.map((word) => toPascalCase(word))?.join(' ');
};
export const toPascalCase = (str: string) => {
  if (!str) {
    return str;
  }
  return str?.length > 0
    ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    : str;
};

export const dataIdDynamic = (item: string) => {
  return `${item?.charAt(0).toLowerCase() + item?.slice(1)}`.replace(/\s/g, '');
};

export function equalsIgnoringCase(text: string, other: string) {
  return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
}
