import React from 'react';
import { useTranslation } from 'react-i18next';
import { toPascalCase } from 'common';
import { Label, Input } from '@sentaraui/optimahealth_web';

interface UserNameProps {
  firstName: string;
  lastName: string;
}

function UserName({ firstName, lastName }: UserNameProps) {
  const { t } = useTranslation();
  const CapitalizeFistName = toPascalCase(firstName);
  const CapitalizeLastName = toPascalCase(lastName);
  return (
    <div className="row pb-2" data-testid="username_component">
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <div className="mb-4">
          <Label
            dataTestId={'firstNameLabel'}
            htmlFor="profileFirstName"
            children={t('First Name')}
            className="input-label"
          />
          <Input
            className="form-input"
            type="text"
            value={CapitalizeFistName}
            aria-label="profileFirstName"
            id="profileFirstName"
            dataTestId={'profileFirstName'}
            disabled
            readOnly
          />
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <div className="mb-4">
          <Label
            dataTestId={'lastNameLabel'}
            htmlFor="profileLastName"
            children={t('Last Name')}
            className="input-label"
          />
          <Input
            className="form-input"
            type="text"
            value={CapitalizeLastName}
            aria-label="profileLastName"
            id="profileLastName"
            dataTestId="profileLastName"
            disabled
            readOnly
          />
        </div>
      </div>
    </div>
  );
}

export default UserName;
