/**
* To store the boolean value into website local storage
* @param localKey: It is to store the key of local storage
* @param convertBoolean: It is to store the value of local storage
*/

import {
  getEncryptedCacheStorage,
  setEncryptedCacheStorage,
  deleteCacheStorage
} from "@platform/cachestorage";

export const setLocalStorageBoolean = (localKey: string, convertBoolean: boolean) => {
    localStorage.setItem(localKey, convertBoolean.toString());
  }
  
  /**
  * To get the boolean value from to pass key of the local storage
  * @param localKey: It is to get the key of local storage
  * @returns
  */
  
  export const getLocalStorageBoolean = (localKey: string) => {
    return stringToBoolean(localStorage.getItem(localKey)|| 'false' )
  }

  function stringToBoolean(val:string) {
    return !!JSON.parse(String(val).toLowerCase());
}

export const getLocalStorageString = (value: string) => {
  return localStorage.getItem(value) || '';
};

/**
  * To encrypt the  value  and set in cache storage
  * @param localKey: It is to set the key of cache storage
  * @param value: It is to set the value of cache storage for specified key
  * @param timeStamp: TimeStamp
  * @returns
*/
  export const setEncryptedValue = (localKey: string, value:string, timeStamp:number=60) => {
    setEncryptedCacheStorage(localKey, value, timeStamp, process.env.REACT_APP_SECRET_PASS)
  }

/**
  * To get the decrypted value from cache storage
  * @param localKey: It is to get the decrypted cache storage value
  * @returns the decrypted cache storage value based on the localkey
*/
export const getDecryptedValue = async (localKey: string) => {
  try {
    let response  = await getEncryptedCacheStorage(localKey, process.env.REACT_APP_SECRET_PASS);
    return response?.data;
  } catch (error) {
    return null;
  }
}

/**
  * To delete all the value from cache storage
  * @return void
*/
export const deleteEncryptedValue =  ():void => {
  deleteCacheStorage()
}


  
