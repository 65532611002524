import { useState, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { t } from 'i18next';
import {
    UserDetails,
    setLocalStorageBoolean,
    signIn,
    SignOutHandler,
    deleteEncryptedValue
  } from 'common';
import {
    Button,
    Headings,
    ModalOverlayWithoutClose
} from '@sentaraui/optimahealth_web';

function IdleTimeoutContainer() {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
    const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const signOut = SignOutHandler();
    
    // Initialize continue session handler function
    const handleContinueSession = () => {
        setModalIsOpen(false);
        if (sessionTimeoutRef.current !== null) {
            clearTimeout(sessionTimeoutRef.current);
          }
    }
    
    const onIdle = () => {
        setModalIsOpen(true);
        sessionTimeoutRef.current = setTimeout(alert, 60000);
    }
    const alert = () => {
        localStorage.clear();
        deleteEncryptedValue();
        window.location.href = signIn;
        setModalIsOpen(false)
        if (sessionTimeoutRef.current !== null) {
            clearTimeout(sessionTimeoutRef.current);
          }
        setLocalStorageBoolean(UserDetails?.inActiveSignOut, true)
    }
    
    useIdleTimer({
        onIdle,
        timeout: 840 * 1000,
        name: 'idle-timer',
      })

    return (
        <div>
            <ModalOverlayWithoutClose
                isOpen={modalIsOpen}
                overlayInfo="Modal information"
            >
                <div data-testid="timeOut-title">
                    <Headings
                        className="redhat_regular font-twentyfour fw-700 mb-3 me-4"
                        level={3}
                        text={t('session.label.modalTitle')}
                    />
                    <p data-testid="sessionModalDescription">
                        {t('session.label.modalDescription')}
                    </p>
                    <div className="row pt-2 pb-2">
                        <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
                            <Button
                                dataTestId={'modalOkButton'}
                                children={t('session.label.modalOk')}
                                variant={'primary_btn btn_block_mob'}
                                type="submit"
                                onClick={handleContinueSession}
                            />
                        </div>
                        <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
                            <Button
                                dataTestId={'cancelButton'}
                                children={t('session.label.modalCancel')}
                                variant={'primary_btn btn_block_mob'}
                                onClick={signOut}
                            />
                        </div>
                    </div>
                </div>
            </ModalOverlayWithoutClose>
        </div>
    )
}

export default IdleTimeoutContainer;