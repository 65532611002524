import { useMySSOUrl } from '@sentara/sentara-api-hooks-core';
import React, { useState, useContext } from 'react';
import { GlobalContext } from 'context';
import { useNavigate } from 'react-router-dom';
import { TilesIds, SSOKeys, Navigate, authorizationUrl, formsAndDocumentsUrl, benefitsURL, memberIDCardUrl, medicalClaimsURL, myPlanUrl, MyPlanTabs, errorhandler } from 'common';
import {
  Loader
} from '@sentaraui/optimahealth_web';
interface Tile {
  name: string;
  icon: React.JSX.Element;
  id: string;
}
interface Tiles {
  allTiles: Tile[];
}



const HomeTiles = ({ allTiles }: Tiles) => {
  const { getFeature } = useContext(GlobalContext);
  
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  // Redirect to Oracle contact us page
  const redirectToContact = () => {
  // Check if ContactusSalesForce property of getFeature is falsy, 
  // If it's truthy, navigate to homeContactUs
  if(!getFeature?.ContactusSalesForce) {
    const form = document.getElementById('contactUS') as HTMLFormElement;
    form.submit();
  } else {
    navigate(Navigate.homeContactUs);
  }
};
  //Create an object of tile actions
const tileActions = {
  [TilesIds.findDoctor]: () => getAPIUrl(SSOKeys.findDoctor),
  [TilesIds.viewClaims]: () => navigate(medicalClaimsURL),
  [TilesIds.ViewIDCard]: () => navigate(memberIDCardUrl),
  [TilesIds.BenefitsSnapshot]: () => navigate(benefitsURL),
  [TilesIds.MyProfile]: () => {
    navigate(Navigate.profile);
  },
  [TilesIds.UrgentCareSearch]: () => getAPIUrl(SSOKeys.UrgentCareSearch),
  [TilesIds.ContactUs]: () => redirectToContact(),
  [TilesIds.MyPlan]: () => navigate(`${myPlanUrl + MyPlanTabs.overViewID}`),
  [TilesIds.FormsAndDocuments]: () => navigate(formsAndDocumentsUrl),
  [TilesIds.Authorizations]: () => navigate(authorizationUrl),
  [TilesIds.VirtualVisit]: () => getAPIUrl(SSOKeys.VirtualVisit),
  [TilesIds.PayMonthlyPremium]: () => getAPIUrl(SSOKeys.PayMonthlyPremium),
  [TilesIds.HealthReimbursementAccount]: () => getAPIUrl(SSOKeys.HealthReimbursementAccount),
  [TilesIds.HealthSavingsAccount]: () => getAPIUrl(SSOKeys.HealthSavingsAccount),
};
 
const clickTile = (tileId: string) => {
  const action = tileActions[tileId];
  action();
};
  const { SSOUrlType } = useMySSOUrl();
  const getAPIUrl = async (type: string) => {
    setLoading(true);
    const res = await SSOUrlType(type); // Get SSO URL from API
    if (res?.data?.ssoUrl) {
      window.open(res?.data?.ssoUrl, '_blank'); // Open SSO URL in a new tab
    } else if (res?.errorCode) {
      errorhandler(res?.errorCode);
    }
    setLoading(false);
  };

  return (
    <>
      {allTiles?.length > 0 &&
        allTiles.map((item: Tile) => {
          return (
            <button
              onClick={() => clickTile(item.id)}
              data-testid={`${item.id}`}
              className="grid-col d-flex flex-column justify-content-center align-items-center bg-transparent home-tile-link"
              key={item.id}
            >
              <span>{item.icon}</span>
              {item.name}
            </button>
          );
        })}
      {loading && <Loader />}
    </>
  );
};

export default HomeTiles;
