import { t } from 'i18next';
import React, { useEffect, useMemo, useState, useContext, memo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChangeProfileImage from './ChangeProfileImage';
import EmailChange from './EmailChange';
import UpdateSecurityQuestions from './UpdateSecurityQuestions';
import HomeAddressDetails from './HomeAddressDetails';
import AlternativeAddressDetails from './AlternativeAddressDetails';
import ChangePhoneNumber from './ChangePhoneNumber';
import UserName from './UserName';
import {
  toPascalCase,
  CheckIconSmall,
  Address,
  Phone,
  userAddressTypeHome,
  profileTabs,
  getDataByAddressType,
  ApiWrapper,
} from 'common';
import {
  usePreferenceDetails,
  useSaveSecurityQuestions,
  useSecurityQuestions,
} from '@sentara/sentara-api-hooks-core';
import PasswordChange from './PasswordChange';
import SetMyPreferences from './SetMyPreferences';
import { GlobalContext } from 'context';
import {
  Button,
  Tabs,
  TabsItem,
  ModalOverlay,
  Headings,
  StyledButtonTabs,
  Loader,
  GlobalError
} from '@sentaraui/optimahealth_web';
import { useForm, FormProvider } from "react-hook-form";
const nameList = ['question', 'questionAnswer'];

const Ids = [
  { id: 'Q1', testId: 'One' },
  { id: 'Q2', testId: 'Two' },
  { id: 'Q3', testId: 'Three' },
];

const updateEmptyAnswer = (data: any) => {
  if (!data || data.length === 0) {
    return [];
  }

  return data?.reduce((arr: any, item: any) => {
    arr.push(
      Object.keys(item).reduce((obj, it) => {
        obj = { ...obj, [it]: it === 'questionAnswer' ? '' : item[it] };
        return obj;
      }, {})
    );
    return arr;
  }, []);
};

function isCheckLocalError(nameList: any, flag = false) {
  return [...Array(3)].reduce((arr, item1) => {
    arr.push(
      nameList.reduce((obj: any, item2: string) => {
        obj = { ...obj, [item2]: flag };
        return obj;
      }, {})
    );
    return arr;
  }, []);
}

function isCheckError(list: any, flag = false) {
  return list.reduce((arr: any, item1: string) => {
    arr.push(
      Object.keys(item1).reduce((obj, item2, idx) => {
        obj = { ...obj, [item2]: flag };
        return obj;
      }, {})
    );
    return arr;
  }, []);
}

const Profile = () => {
  const { getFeature, zipValue, addressValue, serviceNumber, loginMemberId, proxyMemberId } = useContext(GlobalContext);
  const {preferenceDetails} = usePreferenceDetails();
  const { SecurityQuestion } = useSecurityQuestions();
  const [,setSearchParams] = useSearchParams();
  const [securityQuestion1, setSecurityQuestion1] = useState([]);
  const { saveSecurityQuestions } = useSaveSecurityQuestions();
  const [getPhone, setPhone] = useState<any>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [showProfileUpdateInfo, setShowProfileUpdateInfo] =
    useState<boolean>(true);
  const [state, setState] = useState<any>({});
  const [res, setRes] = useState(
    updateEmptyAnswer(state?.securityQuestions) || []
  );
  const [userAddress, setUserAddress] = useState<Address[]>([]);
  const [userPhone, setUserPhone] = useState<Phone[]>([]);
  const [error, setErrors] = useState<any[]>([]);
  const [errorFlag, setErrorFlag] = useState<string[]>([]);
  const navigate = useNavigate();
  const CapitalizeFistName = toPascalCase(state?.firstName);
  const CapitalizeLastName = toPascalCase(state?.lastName);

  const [loader, setLoader] = useState(false);
  const { data: profileResponse, apiError : profileError, callApi } = ApiWrapper(preferenceDetails);
  const methods = useForm({
   defaultValues: {
      question0: '',
      questionAnswer0: '',
      question1: '',
      questionAnswer1: '',
      question2: '',
      questionAnswer2: '',

   }
   });
   const { handleSubmit, getValues,  formState: {errors, isValid } } = methods;
 
  useEffect(() => {
    (async () => {
      setErrors(isCheckLocalError(nameList));
      setErrorFlag(isCheckLocalError(nameList, false));
      const response: any = await SecurityQuestion();
      setSecurityQuestion1(response);
    })();
  }, []);

  useEffect(() => {
    if (serviceNumber !== undefined || serviceNumber !== '') {
      setPhone(JSON.parse(serviceNumber || '{}'));
    }
  }, [serviceNumber]);

  useEffect(() => {
    if (getFeature?.AccountSettings !== undefined) {
      profileAPI();
    }
  }, [loginMemberId, getFeature?.AccountSettings]);

  const profileAPI = async () => {
    let data = {
      memberId: btoa(loginMemberId),
    };
    setLoader(true);
    callApi(data, '', getFeature?.AccountSettings);
  };

  useEffect(() => {
    if (profileError) {
      setLoader(false);
    } else if (profileResponse || profileResponse === '') {
      setState(profileResponse);
      setLoader(false);
    }
    getValues('question0')
  }, [profileResponse, profileError]);

  function closeModal() {
    setIsOpen(false);
    window.location.reload();
  }
  
 
  useEffect(() => {
    (async () => {
      if (state?.securityQuestions) {
        setRes(updateEmptyAnswer(state?.securityQuestions));
        setErrors(isCheckError(state?.securityQuestions));
        setErrorFlag(isCheckError(state?.securityQuestions));
      }
    })();
  }, [JSON.stringify(state?.securityQuestions)]);

  
useEffect(() => {
  getDataByAddressType(state?.address, userAddressTypeHome, setUserAddress);
  getDataByAddressType(state?.phones, userAddressTypeHome, setUserPhone);
}, [state?.dependents]);

  const childrenstate = (
    <option value={userAddress[0]?.state ?? ''}>
      {userAddress[0]?.state ?? ''}
    </option>
  );

  const onHandleChange = (index: any, value: any, key: any) => {
    const tempRes = [...res];
    tempRes[index] = { ...tempRes[index], [key]: value };

    const tempErr: any = [...error];
    tempErr[index] = {
      ...tempErr[index],
      [key]: value === '' ? 'error-border' : '',
    };

    const tempErrFlag: any = [...errorFlag];
    tempErrFlag[index] = {
      ...tempErrFlag[index],
      [key]: value === '',
    };
 
    setErrors(tempErr);
    setErrorFlag(tempErrFlag);
    setRes(tempRes);
  };

  const isButtonEnable = useMemo(() => {
    return (
      zipValue !== '' ||
      addressValue !== ''
    );
    
  }, [zipValue, addressValue]);




  const updateAddress = async () => {
    let modifiedAddress = zipValue !== '' || addressValue !== '';
    let questionAnswerChanged = Object.values(res).every(
      (item: any) => item.questionAnswer !== ''
    );

    let paramSecurityQuestion;
    if (questionAnswerChanged) {
      paramSecurityQuestion = res;
    }
    let address;
    if (modifiedAddress) {
      address = {
        street:
          addressValue !== '' ? addressValue : localStorage.getItem('street'),
        pinCode: zipValue !== '' ? zipValue : state?.address[0]?.zipCode,
      };
    }
   
    const { data }: any = await saveSecurityQuestions(
      proxyMemberId,
      getFeature?.AccountSettings,
      address,
      paramSecurityQuestion
    );
    if (data === '') {
      setIsOpen(true);
    }
  };
 
  const onSubmit = async () => {
    await updateAddress();
  };

  const handleTabClick = (tabId: string) => {
    setShowProfileUpdateInfo(true);
  };
  const handleStyledTabsClick = (tabId: string) => {
    if (tabId === profileTabs.profileDetailsTab) {
      setSearchParams({ id: profileTabs.profileDetailsID });
    } else if (tabId === profileTabs.setMyPreferenceTab) {
      setSearchParams({ id: profileTabs.setMyPreferenceID });
    }
  };

  return (
    <>
      {/* Skip to Main Content link */}
      {loader && <Loader />}
      <a href="#main-content" className="skip-link" tabIndex={1}>
        {t('accessibility.message.skipToMainContent')}
      </a>

      <div>
        <div id="main-content" className="pt-3" role="main">
          <div className="mb-4 pb-2">
            <Headings
              dataTestId="userName"
              level={1}
              text={`${CapitalizeFistName || ''} ${CapitalizeLastName || ''}`}
            />
          </div>
          <div className="row">
          <StyledButtonTabs
              colClassName="col-lg-6 col-md-7 col-sm-12 col-12"
              tabIdHandler={handleStyledTabsClick} 
              tabs={[
                {
                  content: (
                    <div className="tab-content" id="profile-tabContent">
                    <div
                      className="tab-pane show active"
                      id="profile-details"
                      role="tabpanel"
                      aria-labelledby="profile-details-tab"
                    >
                      <div className="row pt-2">
                        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                          <ChangeProfileImage
                            firstName={state?.firstName}
                            lastName={state?.lastName}
                          />
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-12 col-12 ps-lg-5">
                          <UserName
                            firstName={state?.firstName}
                            lastName={state?.lastName}
                          />
                          <div className="border border-1 border-clr mb-5">
                            <div className="profile_btn_tabs_wrapper">
                              <div className="homeAddress">
                                <Tabs
                                  activeTab={'homeAddress'}
                                  tabIdHandler={handleTabClick}
                                  tabConditions={{
                                    homeAddress: true,
                                    alternateAddress: true
                                  }}
                                >
                                  <TabsItem
                                    content={
                                      <div
                                        className="p-4 pb-3"
                                        data-testid="homeAddress"
                                      >
                                        {state?.dependents && userAddress?.length > 0 && (
                                        <HomeAddressDetails
                                         canChangeContactInfo={state?.canChangeContactInfo}
                                         childrenstate={childrenstate}
                                         city={userAddress[0]?.city ?? ''}
                                         dependents={state?.dependents}
                                         street={userAddress[0]?.street ?? ''}
                                         zipCode={userAddress[0]?.zipCode ?? ''}
                                        />
                                      )}
                                      </div>
                                    }
                                    tabId="homeAddress"
                                    tabName={t('settings.label.homeAddress')}
                                  />
                                  <TabsItem
                                    content={
                                      <div className="p-3">
                                        <AlternativeAddressDetails
                                          getPhone={getPhone?.data}
                                        />
                                      </div>
                                    }
                                    tabId="alternateAddress"
                                    tabName={t('settings.label.alternateAddress')}
                                  />
                                </Tabs>
                              </div>
                            </div>
                          </div>
                          {showProfileUpdateInfo && (
                            <>
                              {state?.phones  && (
                                <ChangePhoneNumber
                                  phoneNumber={userPhone[0]?.phoneNumber ?? ''}
                                  canChangeContactInfo={!state?.canChangeContactInfo}
                                />
                              )}
         
                              <EmailChange
                                emailId={state?.email}
                              />
         
                              <PasswordChange
                                changePassword={changePassword}
                                setChangePassword={setChangePassword}
                              />
                              <hr className="mt-3 mb-3 pb-1" />
                              {(errors?.questionAnswer0 || errors?.questionAnswer1 || errors?.questionAnswer2) && (                       
                                <GlobalError variant="mb-3" dataTestId="securityAnsError">
                                      {t('settings.errors.securityAnsError')} 
                                </GlobalError>
                              )}
                              <FormProvider {...methods}>
                            <form data-testid="submit_form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                              <UpdateSecurityQuestions
                                securityQuestion1={securityQuestion1}
                                hasStyle={false}
                                placeholder
                                {...{
                                  res,
                                  setRes,
                                  Ids,
                                  onHandleChange,
                                }}
                              />
         
                              <hr />
                              <div className="row pt-4 pb-2">
                                <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4">
                                  <Button
                                    dataTestId={'submitButton'}
                                    children={t('settings.label.saveChanges')}
                                    variant={
                                      isValid || isButtonEnable
                                        ? 'primary_btn btn_block_mob'
                                        : ' btn_block_mob disabled_btn'
                                    }
                                    type="submit"
                                  />
                                </div>
                                <div className="col-lg-auto col-md-auto col-sm12 col-12 mb-4">
                                  <Button
                                    dataTestId={'cancelButton'}
                                    children={t('settings.label.cancel')}
                                    variant={'secondary_btn btn_block_mob'}
                                    onClick={() => {
                                      navigate('/dashboard');
                                    }}
                                  />
                                </div>
                                {isOpen && (
                                  <ModalOverlay
                                    isOpen
                                    onClose={closeModal}
                                    overlayInfo="Modal information"
                                  >
                                    <div data-testid="memberIdModal">
                                      <div className="d-flex align-items-center justify-content-center green-color mb-3">
                                        <CheckIconSmall />
                                      </div>
                                      <h5
                                        data-testid="loggedMessage"
                                        className="label_logged_message"
                                      >
                                        {t('settings.label.success')}{' '}
                                      </h5>
                                    </div>
                                  </ModalOverlay>
                                )}
                              </div>
                              </form>
                              </FormProvider>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  ),
                  id: 'profile-details',
                  label: t('settings.label.profileDetails'),
                },
                {
                content: (
                  <>
                    {' '}
                      {getFeature?.AccountSettings && (
                        <div
                          className="tab-pane"
                          id="acc-settings"
                          role="tabpanel"
                          aria-labelledby="acc-settings-tab"
                        >
                          <SetMyPreferences
                            deliveryPreference={
                              state?.deliveryPreferences ?? []
                            }
                            languagePreference={state?.languagePreference}
                            smsConsent={state?.smsConsent}
                          />
                        </div>
                      )}
                  </>
                ),
                id: 'acc-settings',
                label: t('settings.label.setMyPreferencesTitle'),
              },
              
              ]}
                />
          
          </div>
     
        </div>
      </div>
    </>
  );
};

export default memo(Profile);
