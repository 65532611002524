import { getDecryptedValue, UserDetails } from 'common';


// Function to check if an API response is safe (not null, empty, or undefined).
export const apiResponseSafeCheck = (response: any) => {
  return response !== null && response !== '' && response !== undefined;
}

// React component to display a common error message.
export function CommonErrorMessage({ message }: { message: string }) {
// If no message is provided, render nothing.
if (!message) return null;

// Render the provided error message within a styled div.
return (
  <div className='mb-3 pb-3'>
    {message}
  </div>
);
}

// Async function to check if the user is a guest.
export const isGuest = async () => {
try {
  // Attempt to retrieve and parse guest details from encrypted storage.
  const personalDetails = await getDecryptedValue(UserDetails.guest);
  const guest = JSON.parse(personalDetails || '{}');
  const isGuest: boolean = guest?.isGuest;
  return isGuest;
} catch (error) {
  // Return false if there's an error during the process.
  return false;
}
}

/**
* Filters and sets profile data based on the specified address type.
* @param data - The array of data containing profile information.
* @param addressType - The type of address to filter by.
* @param setterFunction - The function to set the filtered data in the UI.
*/
export const getDataByAddressType = async (data: any[], addressType: string,setterFunction: (filteredData: any[]) => void) => {
 // Extracted comparison logic into a separate function for better readability.
 const matchesAddressType = (item: { addressType: string; phoneType: string; }) =>
 (item?.addressType?.toUpperCase() || item?.phoneType?.toUpperCase()) === addressType.toUpperCase();

// Filter the data based on the specified address type (case-insensitive).
const filteredData = data ? data.filter(matchesAddressType) : [];

// Update the UI using the provided setter function with the filtered data.
setterFunction(filteredData);
};
