import React from 'react';
import Link from './link/Link';

const DocumentLink: React.FC<{
  link: any;
  text: any;
  styleClass: any;
  dataTestId: string;
}> = ({ link, text, styleClass, dataTestId }) => {
  return (
    <li className={`redhat_bold fw-700 pb-4 pt-4 ${
      text === 'Contact Us' ? 'd-none' : ''
    }`}>
      <Link
        link={link}
        text={text}
        styleClass={undefined}
        dataTestId={dataTestId}
      />
    </li>
  );
};
export default DocumentLink;
