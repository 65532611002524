import { t } from 'i18next';
import { useState, useEffect, useContext } from 'react';
import { useGetAddress, useSaveSecurityQuestions } from '@sentara/sentara-api-hooks-core';
import { GlobalContext } from 'context';
import {
  Label,
  Select,
  Input,
  Button,
  ModalOverlay,
} from '@sentaraui/optimahealth_web';
import { CheckIconSmall } from '../../common/Icons';

interface HomeAddressDetailsProps {
  dependents: { name: string }[];
  street: string;
  zipCode: string;
  city: string;
  childrenstate: React.ReactNode;
  canChangeContactInfo: boolean;
  hideUpdate?: boolean;
}

const HomeAddressDetails = ({
  street,
  zipCode,
  city,
  childrenstate,
  canChangeContactInfo,
  hideUpdate = true
}: HomeAddressDetailsProps) => {
  const [pinCode, setPincode] = useState(zipCode);
  const [changeAddress, setChangeAddress] = useState(street);
  const { getAddress } = useGetAddress();
  const { setZipValue, setAddressValue, addressValue, getFeature, proxyMemberId } = useContext(GlobalContext);
  const { saveSecurityQuestions } = useSaveSecurityQuestions();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setChangeAddress(street);
  }, [street]);

  useEffect(() => {
    setPincode(zipCode);
  }, [zipCode]);

  useEffect(() => {
    if (pinCode?.length === 5) {
      if (pinCode !== zipCode) {
        getAddress(pinCode)
        setZipValue(pinCode);
      } else {
        setZipValue('');
        setPincode(zipCode);
      }
    }
  }, [pinCode]);

  const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPincode(e.target.value);
  };
  const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setChangeAddress(e.target.value);
  };

  useEffect(() => {
    localStorage.setItem('street', street);
    let tempValue = street;
    if (changeAddress !== tempValue) {
      setAddressValue(changeAddress);
    } else {
      setAddressValue('');
      setChangeAddress(street);
    }
  }, [changeAddress]);

  const submitPreference = async () => {
    let modifiedAddress = pinCode !== '' || addressValue !== '';
    let address;
    if (modifiedAddress) {
      address = {
        street:
          addressValue !== '' ? addressValue : localStorage.getItem('street'),
        pinCode: pinCode !== '' ? pinCode : zipCode,
      };
    }
    let paramAddress;
    if (modifiedAddress) {
      paramAddress = address;
    }
    const { data }: any = await saveSecurityQuestions(
      proxyMemberId,
      getFeature?.AccountSettings,
      paramAddress
    );
    if (data === '') {
      setIsOpen(true);
    }
  }


  return (<>
    <div className="d-flex flex-lg-row flex-md-row flex-column gap-3 align-items-start align-items-lg-end align-items-md-end mb-2">
      <div className="flex-1 w-100">
        <div className="mb-3">
          <Label
            dataTestId={'addressLabel'}
            htmlFor="profileStAddress"
            children={t('Street Address')}
            className="input-label"
          />
          <Input
            className="form-input homeaddress-formcontrol"
            type="text"
            placeholder={''}
            value={changeAddress}
            id="profileStAddress"
            dataTestId="profileStAddress"
            onChange={onChangeAddress}
            disabled={!canChangeContactInfo}
          />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
            <Label
              dataTestId={'cityLabel'}
              htmlFor="profileCity"
              children={t('City')}
              className="input-label"
            />
            <Input
              className="form-input homeaddress-formcontrol"
              type="text"
              placeholder=""
              value={city}
              id="profileCity"
              dataTestId="profileCity"
               disabled
              readOnly
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
            <Label
              dataTestId={'stateLabel'}
              htmlFor="profileState"
              children={t('State')}
              className="input-label"
            />
            <div className="select_disabled">
              <Select
                dataTestId="stateSelect"
                className="form-select homeaddress-formcontrol"
                defaultValue={''}
                children={childrenstate}
                 disabled
                readOnly
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
            <Label
              dataTestId={'zipCodeLabel'}
              htmlFor="profileZipCode"
              children={t('Zipcode')}
              className="input-label"
            />
            <Input
              className="form-input homeaddress-formcontrol"
              type="text"
              placeholder={''}
              value={pinCode}
              id="profileZipCode"
              dataTestId="profileZipCode"
               disabled={!canChangeContactInfo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onchange(e);
              }}
            />
          </div>
        </div>
      </div>

      {!hideUpdate && <Button
        dataTestId={'submitButton'}
        children={t('settings.label.update')}
        className={'secondary_btn btn-medium profile-update-btn mb-3'}
        onClick={() => { submitPreference()}}
         disabled={!canChangeContactInfo}
        aria-label={t('accessibility.message.updatePhone')}
      />
      }
    </div>
    {isOpen && (
      <ModalOverlay
        isOpen
        onClose={() => { setIsOpen(false) }}
        overlayInfo="Modal information"
      >
        <div data-testid="memberIdModal">
          <div className="d-flex align-items-center justify-content-center green-color mb-3">
            <CheckIconSmall />
          </div>
          <h5
            data-testid="loggedMessage"
            className="label_logged_message"
          >
            {t('settings.label.success')}{' '}
          </h5>
        </div>
      </ModalOverlay>
    )}
  </>
  );
};

export default HomeAddressDetails;
