import { useEffect, useState, useContext } from 'react';
import { t } from 'i18next';
import { useSavePhone } from '@sentara/sentara-api-hooks-core';
import { useForm } from 'react-hook-form';
import { Button, Label, ErrorMessage, InputField } from '@sentaraui/optimahealth_web';
import { getMaxLength } from 'common';
import { GlobalContext } from 'context';

interface UpdatePhoneProps {
  phoneNumber: string;
  canChangeContactInfo: boolean;
}

const ChangePhoneNumber = ({ phoneNumber, canChangeContactInfo }: UpdatePhoneProps) => {
  const { savePhone } = useSavePhone(); /* use save phone api */
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { getFeature, proxyMemberId } = useContext(GlobalContext);
  const [getphoneNumber, setPhoneNumber] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorShow, setErrorShow] = useState<boolean>(false);
  /* form hook props call */
  const { register, handleSubmit, watch } = useForm({
    mode: 'onBlur',
  });


  /* format phone number */
  const formatPhoneNumber = (item: string) => {
    if (!item) {
      return item;
    }
    const formattedNumber = item.replace(/[^\d]/g, '');
    const phoneNumberLength = formattedNumber.length;
    if (phoneNumberLength < 4) {
      return formattedNumber;
    }
    if (phoneNumberLength < 7) {
      return `${formattedNumber.slice(0, 3)}-${formattedNumber.slice(3)}`;
    }
    return `${formattedNumber.slice(0, 3)}-${formattedNumber.slice(
      3,
      6
    )}-${formattedNumber.slice(6, 10)}`;
  };

  const savedPhoneNumber = formatPhoneNumber(getphoneNumber); /* formate phone number */
  const watchPhoneID = watch('phoneNumber'); /* reference phone number */
  /* Determine on submit */
  const onSubmit = async (e: React.FormEvent) => {
    e?.preventDefault();
    if (getphoneNumber.length === 10) {
      await savePhone(proxyMemberId, getFeature?.AccountSettings, getphoneNumber.replaceAll('-', ''));
      setIsDisabled(true);
      setErrorShow(false);
    }
  };

  useEffect(() => {
    setErrorMessage('');
  }, [watchPhoneID]);

  useEffect(() => {
    setPhoneNumber(phoneNumber);
  }, [phoneNumber]);
  /* enable phone */
  const enablePhone = async (e: React.MouseEvent<HTMLElement>) => {
    e?.preventDefault();
    setIsDisabled(false);
  };
  /* on phone change */
  function onPhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.replaceAll('-', '');
    setPhoneNumber(value);
    if (value?.length < 10) {
      setErrorMessage(t('settings.errors.phoneNumber') ?? '');
      setErrorShow(true);
    } else {
      setErrorShow(false);
    }
  }
  const isErrorPresent = errorMessage !== "";
  const isDisabledClass = isDisabled ? "secondary_btn" : "primary_btn";
  const errorClass = isErrorPresent ? "btn-medium profile-update-btn" : "btn-medium profile-update-btn error_btn_height";
  const variant = `${isDisabledClass} ${errorClass}`;

  return (
    <form onSubmit={handleSubmit(()=>onSubmit)}>
      <div className={`d-flex flex-lg-row flex-md-row flex-column gap-3 align-items-start align-items-lg-center align-items-md-center mb-3`}
      >
        <div className="flex-1 w-100">
          <div className="pos-rel max_height">
            <Label
              dataTestId={'phoneUpdateLabel'}
              htmlFor="profileNumber"
              children={t('Phone')}
              className="input-label"
            />
            <InputField
              className={
                errorShow ? 'error-border form-input' : 'form-input'
              }
              type="text"
              value={savedPhoneNumber}
              id="profileNumber"
              data-testid="phoneInput"
              disabled={isDisabled}
              placeholder={t('settings.label.phoneNumber')}
              maxLength={getMaxLength.phoneNumber}
              {...register('phone', {
                required: true,
                validate: (value) => value !== '',
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPhoneChange(e)}
            />
            {errorShow && <div className='mt-1'><ErrorMessage className="m-0" children={errorMessage} /></div>}
          </div>
        </div>
        <Button
          dataTestId={'submitButton'}
          children={t('settings.label.update')}
          variant={variant}
          onClick={(e: React.MouseEvent<HTMLElement>) => (isDisabled ? enablePhone(e) : onSubmit(e))}
          disabled={canChangeContactInfo}
          aria-label={t('accessibility.message.updatePhone')}
        />
      </div>
    </form>
  );
};

export default ChangePhoneNumber;
