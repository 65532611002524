import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemberIDFax } from '@sentara/sentara-api-hooks-core';
import { errorhandler,  getMaxLength, NoSpecialCharacter, OnlyNumbers  } from 'common';
import {
  ErrorMessage,
  Button,
  ModalOverlay,
  InputField,
  GlobalError,
  Label,
  TextArea
} from '@sentaraui/optimahealth_web';
type FormInputs = {
  recipientPhone: string;
  recipientName: string;
  comments: string;
}

function MemberIdCardFax({ setIsOpen, isOpen }: any) {
  //  Initialize useTranslation hook
  const { t } = useTranslation();
  const {  handleSubmit,  register,  reset,setValue, formState: { errors,  isValid } } = useForm<FormInputs>({}); /* useForm props extract */
  const [errorMessage, setErrorMessage] = useState<string>("");
  
  // Initializing the memberIDFaxType hook to fetch member ID fax type
  const { memberIDFaxType } = useMemberIDFax();

  // Function to handle form submission
  const saveData = async(data: FormInputs) => { 
    
    interface faxResponse{
      data?: string;
      errorCode?: string;
    }
      const response = await memberIDFaxType(Number(data?.recipientPhone),data?.comments, data?.recipientName) as faxResponse  
      if (response?.data == '') {
        setErrorMessage(t('requestUserId.label.resourceErr') ?? ''); // 204 no content
      } else if (response?.errorCode) {
        if ([401, 440].includes(Number(response.errorCode))) {
          errorhandler(response.errorCode); // 401 unauthorized error
        } else {
          setErrorMessage(t('requestUserId.label.serviceError') ?? ''); // 500 internal server error
        }
      } else { 
        onClose(); // close the popup on success
      }  
      
  };

  /* onclose function to close popup and reset the fields to blank on load */
  const onClose = () => {
    reset();
    setErrorMessage('');
    setIsOpen(false);
  };
  /* To remove the error message */
 const removeGlobalError = () => {
  setErrorMessage('');
 }

  return ( 
    <div className="modal_popup_login">
      <ModalOverlay
        isOpen={isOpen}
        onClose={onClose}
        overlayInfo="Member ID card- Fax information overlay"
      >
        <h5
          className="memoverlay-title redhat_medium mb-4"
          data-testid="memberIdFaxHeading"
        >
          {t('requestUserId.message.memberIdFaxHeading')}
        </h5>
        {errorMessage ? <GlobalError variant="mb-3" dataTestId="memberIdFaxHeading">
                     {errorMessage}
                    </GlobalError> : null }
        <form onSubmit={handleSubmit(saveData)} autoComplete="off">
          
            <div className="mb-3 pos-rel">
               <Label className="pb-1" dataTestId={'recipientNameLabel'}
                      htmlFor="recipientName"
                      children={t('activation.label.recipientName')}
                />
            <InputField
              id="recipientName"
              className={ errors.recipientName ? 'error-border form-input'  : 'form-input' }
              placeholder={t('requestUserId.label.recipientName')}
              data-testid="recipientNameInput"
              onFocus={removeGlobalError}
               {...register('recipientName', { required: true,
              onChange: (e:React.ChangeEvent<HTMLInputElement>) => {
                             setValue('recipientName', e.target.value.replace(NoSpecialCharacter , ''));
              }})}
                />               
               {errors.recipientName && (
                   <ErrorMessage dataTestId="reciepientNameInputError"
                children={t('requestUserId.message.reciepientNameRequired')}
              />
            )}
           
          </div>
          <div className="mb-3 pos-rel">
                        <Label
                          className="pb-1"
                          dataTestId={'recipientPhoneLabel'}
                          htmlFor="recipientPhone"
                          children={t('activation.label.phoneNo')}
                        />
            <InputField
              id="recipientPhone"
              maxLength={10}
              className={ errors.recipientPhone ? 'error-border form-input' : 'form-input' }
              placeholder={t('requestUserId.label.phoneNumber')}                           
              type='text'
              data-testid="recipientPhoneInput"
              onFocus={removeGlobalError}
               {...register('recipientPhone', {
                            required: true,
                            maxLength: getMaxLength?.maxPhoneNumber,
                            minLength: getMaxLength?.maxPhoneNumber,
                            onChange: (e:React.ChangeEvent<HTMLInputElement>) => {
                             setValue('recipientPhone', e.target.value.replace(OnlyNumbers , ''));
                            }
                          })} 
            />           
            {errors.recipientPhone && (
                   <ErrorMessage dataTestId="phoneNumberInputError"
                children={t('requestUserId.message.phoneNumberRequired')}
              />
            )}
          </div>
          
          <div className="mb-3 pos-rel">
                        <Label
                         className="pb-1"
                          dataTestId={'commentsInputLabel'}
                          htmlFor="commentsInput"
                          children={t('activation.label.comment')}
                        />
            <TextArea
              data-testid="commentsInput"
              className="form-input"
              placeholder={t('requestUserId.label.comment')} 
              id="comment"
              rows={4}
              onFocus={removeGlobalError}
              {...register('comments', {
                onChange: (e:React.ChangeEvent<HTMLInputElement>) => {
                 setValue('comments', e.target.value);
                }
              })}
            />
          </div>
          <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-4 text-md-center text-lg-start mb-4">
            <Button
              dataTestId={'continueButton'}
              children={t('activation.label.continue')}
              type="submit"
              variant={isValid ? 'primary_btn btn_block_mob' : 'primary_btn btn_block_mob disabled_btn' }
            />
          </div>
        </form>
      </ModalOverlay>
    </div>
  );
}

export default MemberIdCardFax;
