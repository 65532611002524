import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  useProfileImage,
  useUploadPhoto,
} from '@sentara/sentara-api-hooks-core';
import ProfileFirstLetter from './ProfileFirstLetter';
import { ErrorMessage, Image, Loader, InputField } from '@sentaraui/optimahealth_web';

interface ChangeProfileImageProps {
  firstName: string;
  lastName: string;
}

const ChangeProfileImage = ({ firstName, lastName }: ChangeProfileImageProps) => {
  const { getProfileImage, state } =
    useProfileImage(); /* profile image API to get profile image */
  const { uploadPhoto } =
    useUploadPhoto(); /* upload photo API for profile image */
  const { image: profilePhoto } = state; /* profile photo state */
  const [errorMessage, setErrorMessage] =
    useState(''); /* error message state */
    const [loader, setLoader] = useState(false);

  /* validate selected file size */
  const ValidateSelectedInputFile = (file: File) => {
    if (file.size > 9216983) {
      setErrorMessage(t('settings.label.profileImageSizeError') ?? '');
      return false;
    }
    return true;
  };

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.preventDefault();
    setErrorMessage('');
    setLoader(true)
    if (event.target?.files?.length) {
      const inputFile = event.target.files[0];
      const valid = ValidateSelectedInputFile(inputFile);
      if (valid) {
        const formData: any = new FormData();
        formData.append('image', inputFile);
        await uploadPhoto(formData);
        setLoader(false)
      }
    }
  };
  /* get profile image */
  useEffect(() => {
    (async () => {
      await getProfileImage();
    })();
  }, [getProfileImage]);
  
  return (
    <div>
      <div className="row flex-no-wrap align-items-center pos-rel mb-4">
        {loader ? (
          <div data-testid="loader_changeprofileimage">
            <Loader />
          </div>
        ) : (
          profilePhoto && (
            <div className="col-lg-12 col-md-12 col-sm-auto col-auto">
              <div className="d-flex profile_image rounded-circle mx-lg-auto mx-md-auto justify-content-center">
                <Image
                  dataTestId="profileImage"
                  alttext={`${firstName} ${lastName} Profile Image`}
                  className="img-fluid d-block"
                  path={'data:image/png;base64,' + profilePhoto}
                />
              </div>
            </div>
          )
        )}
        {profilePhoto.length <= 0 ? (
          <div className="col-lg-12 col-md-12 col-sm-auto col-auto">
            <ProfileFirstLetter firstName={firstName} lastName={lastName} />
          </div>
        ) : null}
        <div className="col-lg-12 col-md-12 col-sm-auto col-auto text-center flex-1 pt-0 pt-lg-4 pt-md-4 px-0">
          {errorMessage && <ErrorMessage children={errorMessage} />}
          <div className="pos-rel">
            <label aria-hidden="true">
              <span className="upload-photo redhat_bold">
                {t('settings.label.changeProfilePhoto')}
              </span>
            </label>
            <InputField
              dataTestId="uploadProfileImage"
              type="file"
              name="photo"
              className="redhat_bold"
              id="upload-photo"
              onChange={onImageChange}
              accept="image/png, image/jpg, image/jpeg"
              data-focusable="false"
              role="link"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeProfileImage;
