import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components from SentaraUI library
import { Label, Input, Button } from '@sentaraui/optimahealth_web';

// Define the interface for the props passed to PasswordShowEmpty component
interface PasswordShowEmptyProps {
  isDisabled: boolean;
  handleChangeButton: () => void;
}

// Define the PasswordShowEmpty component
const PasswordShowEmpty: React.FC<PasswordShowEmptyProps> = ({
  isDisabled,
  handleChangeButton,
}) => {
  // Initialize localization using react-i18next
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-lg-row flex-md-row flex-column gap-3 align-items-start align-items-lg-end align-items-md-end mb-3">
      {/* Current password input field */}
      <div className="flex-1 w-100">
        <div className="pos-rel">
          {/* Label for the input */}
          <Label
            dataTestId={'passwordUpdateLabel'}
            htmlFor="password"
            className="form-label redhat_medium"
            children={t('settings.label.currentPassword')}
          />
          {/* Input field for current password */}
          <Input
            className="form-input homeaddress-formcontrol"
            dataTestId={'passwordInput'}
            type="password"
            placeholder="********"
            readOnly
            aria-label="currentPassword"
            name="currentPassword"
            disabled={isDisabled}
          />
        </div>
      </div>

      {/* Change password button */}
      <Button
        onClick={() => handleChangeButton()}
        dataTestId={'passwordShowInput'}
        variant={'secondary_btn btn-medium profile-update-btn'}
        children={t('settings.label.change')}
        aria-label={t('accessibility.message.ChangePassword')}
      />
    </div>
  );
};

export default PasswordShowEmpty;
