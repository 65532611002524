import React from 'react';

const Link: React.FC<{
  link: any;
  text: any;
  styleClass: any;
  dataTestId: string;
}> = ({ link, text, styleClass, dataTestId }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener"
    className={`text-decoration-none doc-links ${styleClass}`}
    data-testid={dataTestId}
  >
    {text}
  </a>
);

export default Link;
