import React, { useState, useContext, useEffect } from 'react';
import { t } from 'i18next';
import { useMyDelete, useSaveEmail} from '@sentara/sentara-api-hooks-core';
import { PhoneType_Mobile } from 'common';
import DeleteAccountPopup from '../../templates/accountDelete/DeleteAccountPopup';
import { CheckIconSmall } from '../../common/Icons';
import { GlobalContext } from '../../context';

import {
  Button,
  Headings,
  ModalOverlay,
  Checkbox,
  Input,
  Label,
  ErrorMessage,
  Loader
} from '@sentaraui/optimahealth_web';
import {
  preferencesDetails,
  apiResponseSafeCheck,
  errorhandler,
  signIn,
  memberHome,
} from 'common';


interface DeliveryPreferenceProps {
  document: string;
  electronically: boolean;
  usMail: boolean;
};
interface SmsConsentProps {
  mobileNumber: string;
  optInConsent: boolean;
  phoneType: string;
}

interface PreferenceProps {
  deliveryPreference: DeliveryPreferenceProps[] | null;
  languagePreference: string;
  smsConsent: SmsConsentProps
}

// Define the DeleteAccount component
const SetMyPreferences = ({ deliveryPreference, languagePreference, smsConsent }: PreferenceProps) => {
  const { getFeature } = useContext(GlobalContext);
   // API hooks
   const { MyDeleteType } = useMyDelete();
   const { saveEmail } = useSaveEmail();

  // State variables
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState<boolean>(false);
  const [isGoPaperlessTerms, SetIsGoPaperlessTerms] = useState<boolean>(false);
  const [isPhoneNotificationOpen, SetIsPhoneNotificationOpen] = useState<boolean>(false);
  const [isRegisterMobileNoOpen, setIsRegisterMobileNoOpen] = useState<boolean>(false);
  const [isSuccessPopup, setIsSuccessPopup] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isShowContent, setIsShowContent] = useState<boolean>(false);
  const [paperLessNo, setPaperLessNo] = useState<boolean>(false);
  const [paperLessAccept, setPaperLessAccept] = useState<boolean>(false);
  const [phoneNotificationsAccept, setPhoneNotificationsAccept] = useState<boolean>(false);
  const [checkBoxPhoneError, setCheckBoxPhoneError] = useState<boolean>(false);
  const [checkBoxPaperLessError, setCheckBoxPaperLessError] = useState<boolean>(false);
  const [phoneEmptyError, setPhoneEmptyError] = useState<boolean>(false);
  const [languagePref, setLanguagePref] = useState<string>("");
  const [deliveryPreferenceDetails, setDeliveryPreferenceDetails] = useState<DeliveryPreferenceProps[]>([]);
  const [smsConsentDetails, setSmsConsentDetails] = useState<SmsConsentProps>({} as SmsConsentProps);
  const paperless_EOB_Index = deliveryPreferenceDetails.findIndex((preference: any) => preference.document === preferencesDetails.ExplanationOfBenefits);
  const paperless_SCB_Index = deliveryPreferenceDetails.findIndex((preference: any) => preference.document === preferencesDetails.SummaryOfBenefits);
  const paperless_other_Index = deliveryPreferenceDetails.findIndex((preference: any) => preference.document === preferencesDetails.OtherDocuments);
  const [isChangeMobileNumber, setIsChangeMobileNumber] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [phoneError,setPhoneError] = useState<boolean>(false);
  const [loading,setLoading] = useState<boolean>(false);

  useEffect(() => {
    const deliveryPreferences = deliveryPreference ? [...deliveryPreference] : [];
    setDeliveryPreferenceDetails(deliveryPreferences);
    const electronicallyTrue = deliveryPreferences.filter((deliveryPreference: DeliveryPreferenceProps) => deliveryPreference.electronically===true);
    setLanguagePref(languagePreference);
    setSmsConsentDetails(smsConsent?? { mobileNumber:"", optInConsent:false, phoneType:PhoneType_Mobile });
    if(electronicallyTrue.length===0) {
      setPaperLessNo(true);
    } else {
      setPaperLessNo(false);
    }
  }, [deliveryPreference, languagePreference, smsConsent]);

  const handleYesCheckboxClick = (selected: boolean, checkboxNumber: number) => {
    let updatedDeliveryPreference = [...deliveryPreferenceDetails].map((deliveryPreference: DeliveryPreferenceProps, index: number) => {
      const electronically = (index === checkboxNumber) ? selected : deliveryPreference.electronically;
      return {
        ...deliveryPreference,
        electronically: electronically,
        usMail:  !electronically
      }
    });
    setDeliveryPreferenceDetails(updatedDeliveryPreference);
    //unselect no checkbox
    if (selected) {
      setPaperLessNo(false);
    }
  }

  const handleNoCheckboxClick = (selected: boolean) => {
    // Uncheck all Yes checkboxes
    let updatedDeliveryPreference = [...deliveryPreferenceDetails].map((deliveryPreference: DeliveryPreferenceProps, index: number) => {
      return {
        ...deliveryPreference,
        electronically: false,
        usMail: true
      }
    })
    setDeliveryPreferenceDetails(updatedDeliveryPreference);
    // Check the No checkbox
    setPaperLessNo(selected);
    if (selected) {
      setCheckBoxPaperLessError(false);
    }
  };

  // Function to open the delete account modal
  const submitDelete = (e: any) => {
    setIsOpen(true);
    setIsShowContent(true);
    setIsConfirmOpen(false);
  };

  //Function to close the modal
  function closeModal() {
    setIsOpen(false);
    setIsSaveSuccess(false);
  }
  const openChangePhoneNoModal = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    SetIsGoPaperlessTerms(true);
  };
  const openPhoneNotificationModal = (e:React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    SetIsPhoneNotificationOpen(true);
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) {
      return value;
    }
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) {
      return phoneNumber;
    }
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const openRegMobNoModal = (type:string) => {
    if(type==="change") {
      setIsChangeMobileNumber(true);
      setMobileNumber("");
    } 
    else {
      setIsChangeMobileNumber(false);
      setMobileNumber("");
    }
    setIsRegisterMobileNoOpen(true);
  };
  function closePopUpModal() {
    setIsSuccessPopup(false);
    SetIsGoPaperlessTerms(false);
    SetIsPhoneNotificationOpen(false);
    setIsRegisterMobileNoOpen(false);
    setPhoneError(false);
  }

  const checkPhoneValid = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked || smsConsentDetails?.optInConsent === false) {
      setCheckBoxPhoneError(false);
    } else {
      setCheckBoxPhoneError(true);
    }
  };

  const checkPapperLessValid = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked || paperLessNo) {
      setCheckBoxPaperLessError(false);
    } else {
      setCheckBoxPaperLessError(true);
    }
  };

  // Function to handle account deletion
  const deleteAccount = async () => {
    setIsShowContent(false);
    setIsConfirmOpen(true);
  };

  // Function to confirm account deletion
  const confirmDeleteAccount = async () => {
    const response = await MyDeleteType();
    if (response?.data === '') {
      setIsConfirmOpen(false);
      localStorage.clear();
      window.location.href = signIn;
    }
  };

  // JSX element for phone notifications text
  const phoneNotificationsAndConsentText = (
    <>
      <span data-testid="accept" className="redhat_medium">
        {t('activation.messages.accept')}
      </span>{' '}
      <button
        className="btn-links text-decoration-underline bg-transparent border-0 p-0 redhat_regular"
        data-testid="phoneNotificationConsentTitle"
        onClick={openPhoneNotificationModal}
      >
        {t('setMyPreferences.label.phoneNotificationsAndConsent')}
      </button>
      {t('dot')}
    </>
  );
  // JSX element for terms and conditions text
  const termsAndConditionText = (
    <>
      <span data-testid="accept" className="redhat_medium">
        {t('activation.messages.accept')}
      </span>{' '}
      <button
        className="btn-links text-decoration-underline bg-transparent border-0 p-0 redhat_regular"
        data-testid="termsTitle"
        onClick={openChangePhoneNoModal}
      >
        {t('termsandcondition.label.termsTitle')}
      </button>
      {t('dot')}
    </>
  );

  const LINK_BTN_BLOCK_MOB = 'btn-links darkblue-color ps-0';
  const PRIMARY_BTN_BLOCK_MOB = 'primary_btn';
  const YesForReceiveTextMsg = (
    <>
      <strong>{t('account.label.buttonLableOne')}</strong>,{' '}
      {t('settings.label.wantToReceive')}
    </>
  );
  const NoForReceiveTextMsg = (
    <>
      <strong>{t('account.label.buttonLableTwo')}</strong>,{' '}
      {t('settings.label.notToReceive')}
    </>
  );
  const WantExplanationBenefits = (
    <>
      <strong>{t('account.label.buttonLableOne')}</strong>,{' '}
      {t('settings.label.wantExplanationBenefits')}
    </>
  );
  const WantBenefitsAndCoverage = (
    <>
      <strong>{t('account.label.buttonLableOne')}</strong>,{' '}
      {t('settings.label.wantBenefitsAndCoverage')}
    </>
  );
  const WantOtherDocuments = (
    <>
      <strong>{t('account.label.buttonLableOne')}</strong>,{' '}
      {t('settings.label.wantOtherDocuments')}
    </>
  );
  const NotPaperlessThisTime = (
    <>
      <strong>{t('account.label.buttonLableTwo')}</strong>,{' '}
      {t('settings.label.notPaperlessThisTime')}
    </>
  );

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let error: boolean = false;
    if (phoneNotificationsAccept === false && smsConsentDetails?.optInConsent) {
      setCheckBoxPhoneError(true);
      error = true;
    }

    if(smsConsentDetails?.optInConsent && smsConsentDetails?.mobileNumber === "") {
      setPhoneEmptyError(true);
      error = true;
    }

    if (paperLessAccept === false &&
      !paperLessNo &&
      getFeature?.DocumentDeliveryPreferences &&
      deliveryPreferenceDetails.length>0) {
      setCheckBoxPaperLessError(true);
      error = true;
    }

    if (error === false) {
      setLoading(true);
      let formattedSmsContent = smsConsentDetails?.mobileNumber !=="" ? {...smsConsentDetails} : null;
      const response = await saveEmail(undefined,getFeature?.AccountSettings, undefined, undefined, deliveryPreferenceDetails, undefined, languagePref, formattedSmsContent);
      if(response?.errorCode) {
        errorhandler(response?.errorCode);
      }
      else {
        setIsSaveSuccess(true);
      }
      setLoading(false);
    }

  };

  const checkSavePreferencesEnabled = (): boolean => {

    let enable = false;
    // mobile number is mandatory when optInConsent is true
    if(smsConsentDetails.optInConsent===true && smsConsentDetails?.mobileNumber ==="") {
      enable = false;
    }
    else if (
      ( (paperLessAccept || !getFeature?.DocumentDeliveryPreferences || deliveryPreferenceDetails.length === 0) && phoneNotificationsAccept) ||
      ( (paperLessAccept || !getFeature?.DocumentDeliveryPreferences || deliveryPreferenceDetails.length === 0) && smsConsentDetails?.optInConsent === false) ||
      (paperLessNo && smsConsentDetails?.optInConsent === false) ||
      (paperLessNo && phoneNotificationsAccept)) {
      enable =  true;
    }
    return enable;
  }

  

  const  submitMobileNumber = async()=>{
    const value = mobileNumber.replaceAll('-', '');
    if (value?.length < 10) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
      let mobile = mobileNumber.split("-");
      let formattedMobile = '('+ mobile[0]+ ') ' +mobile[1] + "-" +mobile[2];
      if(!phoneError) {
        setSmsConsentDetails({...smsConsentDetails, mobileNumber:formattedMobile});
        setIsRegisterMobileNoOpen(false);
        setPhoneEmptyError(false);
      }
    }
  }

  const  onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>)=> {
    const value = e.target.value.replaceAll('-', '');
    const formattedNumber = formatPhoneNumber(value);
    setMobileNumber(formattedNumber);
    if (value?.length < 10) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }

  // Return JSX content of DeleteAccount component
  return (
    <div className="set_my_preferences">
       {loading && <Loader />}
      <div className="heading_redhat_24 mb-4">
        {/* Heading */}
        <Headings
          level={2}
          text={t('settings.label.setMyPreferencesTitle')}
          dataTestId="setMyPreferencesTitle"
        />
      </div>

      <form data-testid="submitSetMyPref_form" onSubmit={onSubmit} autoComplete="off">
        <div className="border border-clr p-3 p-lg-4 p-md-4 d-flex flex-column gap-4 mb-4">
          <div>{t('settings.message.connect')}</div>
          {/* Text Messages from Sentara Health Plans start */}
          <section className="">
            <h3>{t('settings.label.textMessagesTitle')}</h3>
            <div className="d-flex flex-column gap-2 pt-2 pb-4">
              <div className="row">
                <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                  <div className="px-4">
                    {t('setMyPreferences.description.textMsgDesc')}
                  </div>
                </div>
              </div>
              <ul className="pref_choose_options">
                <li>
                  <div className="d-flex">
                    <Input
                      id="phoneNotificationYes"
                      className="form-check-input mt-0"
                      children={YesForReceiveTextMsg}
                      name="phoneNotification"
                      type="radio"
                      disabled={false}
                      dataTestId="phoneNotificationYes"
                      checked={smsConsentDetails?.optInConsent}
                      onChange={() => {
                        setSmsConsentDetails({ ...smsConsentDetails, optInConsent: true });
                      }}
                    />
                    <Label
                      htmlFor="phoneNotificationYes"
                      className="form-check-label pad_left_12 check-label-pad"
                      children={YesForReceiveTextMsg}
                    />
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <Input
                      id="phoneNotificationNo"
                      className="form-check-input mt-0"
                      children={NoForReceiveTextMsg}
                      name="phoneNotification"
                      type="radio"
                      disabled={false}
                      dataTestId="phoneNotificationNo"
                      checked={!smsConsentDetails?.optInConsent}
                      onChange={() => {
                        setCheckBoxPhoneError(false);
                        setSmsConsentDetails({ ...smsConsentDetails, optInConsent: false });
                      }}
                    />
                    <Label
                      htmlFor="phoneNotificationNo"
                      className="form-check-label pad_left_12 check-label-pad"
                      children={NoForReceiveTextMsg}
                    />
                  </div>
                </li>
              </ul>
              {apiResponseSafeCheck(smsConsentDetails?.mobileNumber) ?
                <div className="px-4">
                  <small>
                    {t('setMyPreferences.description.textMsgDisclaimer1')}{' '}
                    <strong>{smsConsentDetails?.mobileNumber}</strong>
                    {t('dot')}{' '}
                    {t('setMyPreferences.description.textMsgDisclaimer4')}{' '}
                    <button
                      className="btn-links text-decoration-underline bg-transparent border-0 p-0 redhat_regular"
                      data-testid="phoneNotificationTitle"
                      onClick={(event: React.MouseEvent<HTMLElement>)=>{
                        event.preventDefault();
                        openRegMobNoModal("change")
                      }}
                    >
                      {t('setMyPreferences.description.textMsgDisclaimer5')}
                    </button>
                    {t('dot')}
                  </small>
                </div>
                :
                  <div className="px-4 d-inline">
                      <span className='me-1'>
                        <small>
                            {t('setMyPreferences.description.textMsgDisclaimer2')}{' '}
                        </small>
                      </span>
                      <span className="d-inline-grid">
                        <small>
                          <button
                            className="btn-links text-decoration-underline bg-transparent border-0 p-0 redhat_regular"
                            data-testid="phoneNotificationTitle"
                            onClick={(event: React.MouseEvent<HTMLElement>)=>{
                              event.preventDefault();
                              openRegMobNoModal("register")
                            }}
                          >
                            {t('setMyPreferences.description.textMsgDisclaimer3')}
                          </button>
                          {t('dot')}
                        </small>
                        {phoneEmptyError && (
                          <div
                            id="termsofuse_error"
                            aria-live="polite"
                            aria-atomic="true" 
                            >
                            <ErrorMessage
                              className="mb-0"
                              children={t(
                                  'setMyPreferences.error.phoneEmptyMessage'
                              )}
                            ></ErrorMessage>
                          </div>
                        )}
                      </span>
                  </div> 
              }
              <div className="px-4 d-flex">
                <div className="pos-rel">
                  <Checkbox
                    id="phoneNotificationsAndConsentCheck"
                    className={
                      checkBoxPhoneError
                        ? 'form-check-input mt-0 error-input-bdr'
                        : 'form-check-input mt-0'
                    }
                    children={phoneNotificationsAndConsentText}
                    type="checkbox"
                    dataTestId="phoneNotificationsAndConsentCheck"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      checkPhoneValid(e);
                      setPhoneNotificationsAccept(e.target.checked);
                    }}
                    checked={phoneNotificationsAccept}
                  />
                  {checkBoxPhoneError && (
                      <ErrorMessage className="mb-0"
                        children={t(
                          'setMyPreferences.error.phoneConsent'
                          )}
                      ></ErrorMessage>
                  )}
                </div>
              </div>
            </div>
          </section>
          {/* Text Messages from Sentara Health Plans end */}

          {/* Go green, go paperless start */}
          {(getFeature?.DocumentDeliveryPreferences && deliveryPreferenceDetails.length>0) &&
            <section>
              <h3>{t('settings.label.goGreenGoPaperless')}</h3>
              <div className="d-flex flex-column gap-2 pt-2 pb-4">
                <div className="row">
                  <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                    <div className="px-4">
                      {t('setMyPreferences.description.goPaperlessDesc')}
                    </div>
                  </div>
                </div>
                <ul className="pref_choose_options">
                  {paperless_EOB_Index > -1 &&
                    <li className='d-flex align-items-center'>
                      <Input
                        id="paperless_EOB"
                        className="form-check-input mt-0"
                        name="paperlessEOB"
                        type="checkbox"
                        disabled={false}
                        data-testid="paperless_EOB"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleYesCheckboxClick(e.target.checked, paperless_EOB_Index)}
                        checked={deliveryPreferenceDetails[paperless_EOB_Index].electronically}
                      />
                      <Label className="ms-3">{WantExplanationBenefits}</Label>
                    </li>
                  }
                  {paperless_SCB_Index > -1 &&
                    <li className='d-flex align-items-center'>
                      <Input
                        id="paperless_SCB"
                        className="form-check-input mt-0"
                        name="paperlessSBC"
                        type="checkbox"
                        disabled={false}
                        dataTestId="paperless_SCB"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleYesCheckboxClick(e.target.checked, paperless_SCB_Index)}
                        checked={paperless_SCB_Index > -1 ? deliveryPreferenceDetails[paperless_SCB_Index].electronically : false}
                      />
                      <Label className="ms-3">{WantBenefitsAndCoverage}</Label>
                    </li>
                  }
                  {paperless_other_Index > -1 &&
                    <li className='d-flex align-items-center'>
                      <Input
                        id="paperless_other"
                        className="form-check-input mt-0"
                        children={WantOtherDocuments}
                        name="paperlessOther"
                        type="checkbox"
                        disabled={false}
                        dataTestId="paperless_other"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleYesCheckboxClick(e.target.checked, paperless_other_Index)}
                        checked={paperless_other_Index > -1 ? deliveryPreferenceDetails[paperless_other_Index].electronically : false}
                      />
                      <Label className="ms-3">{WantOtherDocuments}</Label>
                    </li>
                  }
                  <li className='d-flex align-items-center'>
                    <Input
                      id="not_paperless"
                      className="form-check-input mt-0"
                      children={NotPaperlessThisTime}
                      name="notPaperless"
                      type="checkbox"
                      disabled={false}
                      dataTestId="not_paperless"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNoCheckboxClick(e.target.checked)}
                      checked={paperLessNo}
                    />
                    <Label className="ms-3">{NotPaperlessThisTime}</Label>
                  </li>
                </ul>
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div className="px-4">
                      <small>
                        <strong>{t('requestUserId.label.note')}:</strong>{' '}
                        {t('setMyPreferences.description.goPaperlessDisclaimer')}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="px-4 d-flex">
                  <div className="pos-rel">
                    <Checkbox
                      id="termAndConditioncheck"
                      className={
                        checkBoxPaperLessError
                          ? 'form-check-input mt-0 error-input-bdr'
                          : 'form-check-input mt-0'
                      }
                      children={termsAndConditionText}
                      type="checkbox"
                      dataTestId="termsandconditionCheckbox"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        checkPapperLessValid(e);
                        setPaperLessAccept(e.target.checked);
                      }}
                      checked={paperLessAccept}
                    />
                    {checkBoxPaperLessError && (
                      <div className='pt-1'>
                        <ErrorMessage className="mb-0"
                          children={t(
                            'activation.errors.acceptTermsAndCondition'
                          )}
                        ></ErrorMessage>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          }
          {/* Go green, go paperless end */}

          {/* Language Preference start */}
          <section>
            <h3>{t('settings.label.languagePreference')}</h3>
            <div className="d-flex flex-column gap-2 pt-2">
              <div className="px-4">
                {t('setMyPreferences.description.langPrefDesc')}
              </div>
              <div className="px-4">
                {t('setMyPreferences.description.langPrefDescSpanish')}{' '}
                <br aria-hidden="true" />
                {t('setMyPreferences.description.textMsgSpanish')} <a href="tel:18556876260">{t('setMyPreferences.description.langPrefTelephone')}</a> (TTY: 711).
              </div>
              <div className="d-flex gap-5 px-4 pt-4 pb-2 pref_choose_options lang_choose">
                <div className="d-flex">
                  <Input
                    id="langEnglish"
                    className="form-check-input mt-0"
                    children={`English`}
                    name="languagePref"
                    type="radio"
                    disabled={false}
                    dataTestId="langEnglish"
                    value={preferencesDetails.English}
                    checked={languagePref === preferencesDetails.English}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLanguagePref(e.target.value);
                    }}
                  />
                  <Label
                    htmlFor="langEnglish"
                    className="form-check-label pad_left_12 check-label-pad"
                    children={t('languageSwitch.labels.english')}
                  />
                </div>
                <div className="d-flex">
                  <Input
                    id="langSpanish"
                    className="form-check-input mt-0"
                    children={`Spanish`}
                    name="languagePref"
                    type="radio"
                    disabled={false}
                    dataTestId="langSpanish"
                    value={preferencesDetails.Spanish}
                    checked={languagePref === preferencesDetails.Spanish}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLanguagePref(e.target.value);
                    }}
                  />
                  <Label
                    htmlFor="langSpanish"
                    className="form-check-label pad_left_12 check-label-pad"
                    children={t('languageSwitch.labels.spanish')}
                  />
                </div>
              </div>
              <div className="lang_pref_btm py-3 px-4">
                <small className="redhat_medium">
                  {t('setMyPreferences.description.langPrefDisclaimer')}
                  <strong>{t('setMyPreferences.description.langPrefTelephone')}</strong>
                  {t('dot')}
                </small>
              </div>
            </div>
          </section>
          {/* Language Preference end */}
          <div>
            <Button
              dataTestId="save_preferences"
              children={t('setMyPreferences.label.savePreference')}
              id="save_preferences"
              onClick={() => { }}
              variant={checkSavePreferencesEnabled()
                ? 'primary_btn btn_block_mob'
                : 'btn_block_mob disabled_btn'
              }
              type="submit"
            />
          </div>
        </div>

      </form>
      {/* Delete account start */}
      <div className="heading_redhat_24 mb-4">
        <Headings
          level={2}
          text={t('settings.label.deleteAccount')}
          dataTestId="profileDeletAccountHeading"
        />
      </div>
      <div className="pb-2">
        <div className="border border-clr p-4 mb-4">
          {/* Render confirmation message and button to delete account */}
          <p className="pb-2" data-testid="confirmationDeleteAccountInfo">
            {t('settings.label.confirmationDeleteAccountInfo')}
            <a
              data-testid="customerSupportLink"
              className="gold-link"
              href="/home/contact-us"
            >
              {t('settings.label.customerSupportLink')}
            </a>
          </p>
          <Button
            dataTestId={'deleteAccountButton'}
            children={t('settings.label.continueToDeleteAccount')}
            variant={LINK_BTN_BLOCK_MOB}
            onClick={(e: any) => {
              e.preventDefault();
              submitDelete(e);
            }}
          />
        </div>
      </div>
      {/* Render the DeleteAccountPopup component */}
      <DeleteAccountPopup
        isOpen={isOpen}
        closeModal={closeModal}
        isShowContent={isShowContent}
        variant={PRIMARY_BTN_BLOCK_MOB}
        deleteAccount={deleteAccount}
        isConfirmOpen={isConfirmOpen}
        confirmDeleteAccount={confirmDeleteAccount}
      />
      {isSuccessPopup && (
        <ModalOverlay
          isOpen={isSuccessPopup}
          onClose={closePopUpModal}
          overlayInfo="Modal information"
        >
          <div data-testid="memberIdModal">
            <div className="d-flex align-items-center justify-content-center green-color mb-3">
              <CheckIconSmall />
            </div>
            <h5 data-testid="loggedMessage" className="label_logged_message">
              {t('settings.label.success')}{' '}
            </h5>
          </div>
        </ModalOverlay>
      )}
      {/* Delete account end */}

      <ModalOverlay
        isOpen={isGoPaperlessTerms}
        onClose={closePopUpModal}
        overlayInfo="Phone notification and consent overlay"
      >
        <h2 data-testid="goPaperlessTitle">
          {t('setMyPreferences.label.goPaperlessTitle')}
        </h2>
        <div
          data-testid="phoneNotificationMessage"
          className="phone_notify_msg_wrapper"
        >
          <div>
            <div className="d-inline">
              {t('setMyPreferences.message.goPaperlessMsg1')}{' '}
            </div>
            <div className="d-inline">
              <a
                href={memberHome}
                target="_blank"
                rel="noreferrer"
                className="darkblue-color"
              >
                {t('setMyPreferences.message.goPaperlessMsg2')}
              </a>{' '}
            </div>
            <div className="d-inline">
              {t('setMyPreferences.message.goPaperlessMsg3')}
            </div>
          </div>
        </div>
      </ModalOverlay>

      <ModalOverlay
        isOpen={isPhoneNotificationOpen}
        onClose={closePopUpModal}
        overlayInfo="Go paperless overlay"
      >
        <h2 data-testid="phoneNotificationsAndConsentTitle">
          {t('setMyPreferences.label.phoneNotificationsAndConsent')}
        </h2>
        <div
          data-testid="phoneNotificationMessage"
          className="phone_notify_msg_wrapper"
        >
          <div className="d-flex flex-column gap-3">
            <div>{t('setMyPreferences.message.phoneNotificationMessage1')}</div>
            <div>{t('setMyPreferences.message.phoneNotificationMessage2')}</div>
            <div>
              {t('setMyPreferences.message.phoneNotificationMessage3')}{' '}
              <a href="tel:18007419910">{t('setMyPreferences.message.phoneNotificationMessage4')}</a>{t('dot')}{' '}
              {t('setMyPreferences.message.phoneNotificationMessage5')}{' '}
              <a href="tel:18007419910">{t('setMyPreferences.message.phoneNotificationMessage4')}</a>{t('dot')}
            </div>
          </div>
        </div>
      </ModalOverlay>

      {/* register mobile number overlay start */}
      <ModalOverlay
        isOpen={isRegisterMobileNoOpen}
        onClose={closePopUpModal}
        overlayInfo="Register mobile number overlay"
      >
        <h2 data-testid="phoneNotificationsAndConsentTitle" className="mb-4">
          {isChangeMobileNumber ? t('setMyPreferences.description.textMsgDisclaimer5'): t('setMyPreferences.description.textMsgDisclaimer3')}
        </h2>
        <div>
          <div className="mb-4">
            <div className="pos-rel">
              <Label
                className="pb-1"
                htmlFor={'setPrefRegMobNo'}
                dataTestId={'PrefEnterRegMobNo'}
                children={isChangeMobileNumber ? t(`setMyPreferences.label.enterNewMobileNumber`):t(`setMyPreferences.label.enterMobileNumber`)}
              />
              <Input
                id={'setPrefRegMobNo'}
                data-testid={'regMobNo'}
                value={mobileNumber}
                placeholder={t(`setMyPreferences.placeholder.enterMobileNumber`)}
                className={`form-input`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPhoneChange(e)}
              />
              {phoneError && 
                <div
                  id="regMobNo_error"
                  aria-live="polite"
                  aria-atomic="true"
                  className="mt-1"
                >
                  <ErrorMessage className="mb-0"
                    children={t('setMyPreferences.error.enterMobileNumberError')}
                  />
                </div>
              }
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row flex-md-row align-items-center gap-4 mb-4">
            <Button
              dataTestId="submitButton"
              variant={(!phoneError && mobileNumber.length>0) ?
                'primary_btn btn_block_mob'
                : 'btn_block_mob disabled_btn'
              }
              onClick={() => { submitMobileNumber() }}
              children={t('activation.label.submit')}
            />
            <Button
              dataTestId="cancelButton"
              type="button"
              onClick={() => {closePopUpModal() }}
              variant="secondary_btn btn_block_mob"
              children={t('activation.label.cancel')}
            />
          </div>
        </div>
      </ModalOverlay>
      {/* register mobile number overlay end */}

      {/* Save success overlay */}
      {isSaveSuccess && (
        <ModalOverlay
          isOpen = {isSaveSuccess}
          onClose={closeModal}
          overlayInfo="Modal information"
        >
          <div data-testid="memberIdModal">
            <div className="d-flex align-items-center justify-content-center green-color mb-3">
              <CheckIconSmall />
            </div>
            <h5
              data-testid="loggedMessage"
              className="label_logged_message"
            >
              {t('settings.label.preferencesUpdated')}{' '}
            </h5>
          </div>
        </ModalOverlay>
      )}
      {/* Save success overlay */}

    </div>
  );
};

export default SetMyPreferences;
