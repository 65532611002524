import {  EmailRegex, getMaxLength, FormTitles } from 'common';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  Label,
  Button,
  InputField,
  Headings
} from '@sentaraui/optimahealth_web';
import { useForm } from 'react-hook-form';
interface emailData {
  emailID: string;
  confirmEmailID: string;
}
interface EmailVerificationProps {
  formData: any; 
  page: number; 
  setFormData: (response: any) => void; 
  setPage: (response: any) => void; 
  guest: boolean; 
  plantypes?: string;
}

const EmailVerification = ({ formData, page, setFormData, setPage}: EmailVerificationProps) => {
  const { t } = useTranslation();
  const { handleSubmit,  register,  getValues, formState: { errors,  isValid } } = useForm<emailData>({
    mode: 'onChange',
    defaultValues: {
      emailID: formData[page]?.emailID || '',
      confirmEmailID: formData[page]?.confirmEmailID || '',
    },
  });

  /** validation for email and confirm email*/
  const onSubmit = async (data:emailData) => {
    setFormData({ ...formData, [page]: data });
    setPage((currPage: number) => currPage + 1);
  };
  return (
    <>
      <div aria-live="polite">
      {FormTitles[page] !== '' && (
            <>
              <span className="visually-hidden me-1">
                {t('accessibility.message.registrationCurrentPage')} {[page + 1]}
              </span>
              <div className="pb-4 mb-4">
                <Headings
                  dataTestId="registrationHeading"
                  level={1}
                  text={t(FormTitles[page])}
                />
              </div>
            </>
      )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
            <div className="mb-3 pos-rel">
              <Label
                dataTestId="emailInputLabel"
                htmlFor="regEmailAddress"
                children={t('yourPlan.label.emaillbl')}
              />
              <InputField
                id="regEmailAddress"
                maxLength={getMaxLength.email}
                className={`form-input ${errors?.emailID?.type === 'required'  ? 'error-border' : ''
                  }`}
                placeholder={t('yourPlan.label.emaillblPlaceholder')}
                data-testid="emailInput"
                {...register('emailID', {
                              required: true,
                              pattern: {
                                  value: EmailRegex,
                                  message: "Password criteria not match"
                              },
                            })}
               
                aria-describedby="emailAddress_error"
              />
               {errors?.emailID &&  (
                              <div className="mt-1">
                 <ErrorMessage children={t('activation.errors.emailId')} data-testid={'emailUpdate'} />
                </div>
                )}             
            </div>
            <div className="mb-3 pos-rel">
              <Label
                dataTestId="confirmEmailInputLabel"
                htmlFor="confirmEmailAddress"
                children={t('activation.label.confirmEmail')}
              />
              <InputField
                maxLength={getMaxLength.email}
                className={`form-input ${errors.confirmEmailID ? 'error-border': ''
                  }`}
                placeholder={t('activation.label.confirmEmail')}
                id="confirmEmailAddress"
                data-testid="confirmEmailInput"
                {...register('confirmEmailID', {required: true,
                   validate: (value) => {
                   return value === getValues("emailID")
                     }
               })}
                
                aria-describedby="confirmEmail_error"
              />
               {errors.confirmEmailID &&  (
                <div className="mt-1">
                  <ErrorMessage children={t('activation.errors.confirmEmail')} data-testid={'confirmEmailInputError'} />
                </div>
                )}
            </div>
          </div>
        </div>
        <hr />
        <div className="row pt-4 pb-2">
          <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
            <Button
              dataTestId={'continueButton'}
              children={t('activation.label.continue')}
              variant={
                isValid
                  ? 'primary_btn btn_block_mob'
                  : 'btn_block_mob disabled_btn'
              }
              type="submit"
            />
          </div>
          <div className="col-lg-auto col-md-auto col-sm12 col-12 d-grid gap-2 d-md-block mb-4">
            <Button
              dataTestId={'cancelButton'}
              children={t('activation.label.cancel')}
              variant={'secondary_btn'}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  ...{
                    [page]: formData[page]
                      ? Object.keys(formData[page]).reduce((obj, item) => {
                          obj = { ...obj, [item]: '' };
                          return obj;
                        }, {})
                      : {},
                  },
                });
                setPage((currPage: number) => currPage - 1);
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default EmailVerification;